import { API } from "aws-amplify";

import { loadStripe } from "@stripe/stripe-js";

export const getPartnerBulkCheckoutSession = ({
  products,
  dashboard,
  success_url,
  cancel_url,
  checkAppDiscount,
}) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        products: JSON.stringify(products),
        dashboard,
        success_url,
        cancel_url,
        operation: "PARTNER_BULK_PURCHASE",
        checkAppDiscount,
      },
    };

    result = API.get("stripe", `/checkout`, myInit);
  } catch (e) {
    console.log(e, e.stack);
  }

  return result;
};

export const getPartnerSpecialOfferCheckoutSession = ({
  item,
  dashboard,
  success_url,
  cancel_url,
}) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        item,
        dashboard,
        success_url,
        cancel_url,
        operation: "PARTNER_SPECIAL_CERT_BUNDLE",
      },
    };

    result = API.get("stripe", `/checkout`, myInit);
  } catch (e) {
    console.log(e, e.stack);
  }

  return result;
};

export const openPartnerBulkCheckoutSession = async ({
  products,
  dashboard,
  checkAppDiscount = false,
}) => {
  const session = await getPartnerBulkCheckoutSession({
    products,
    dashboard,
    checkAppDiscount,
    success_url: `${window.location.origin}/dashboard?bulk_success=true`,
    cancel_url: `${window.location.href}?cancel=true`,
  });
  console.log(session);

  const { sessionId, err, publishableKey } = session || {};

  if (!!err) {
    console.error("This item is free.");
    window.location.reload();
  } else if (!!publishableKey && !!sessionId) {
    const stripe = await loadStripe(publishableKey);

    sessionStorage.setItem("STRIPE_ACTION", "PENDING");
    await stripe.redirectToCheckout({ sessionId });
  }
};

export const openPartnerSpecialOfferCheckoutSession = async ({
  item,
  dashboard,
}) => {
  const session = await getPartnerSpecialOfferCheckoutSession({
    item,
    dashboard,
    success_url: `${window.location.origin}/dashboard?success=true`,
    cancel_url: `${window.location.href}?cancel=true`,
  });

  const { sessionId, err, publishableKey } = session || {};

  if (!!err) {
    console.log("This item is free.");
    window.location.reload();
  } else if (!!publishableKey && !!sessionId) {
    const stripe = await loadStripe(publishableKey);

    sessionStorage.setItem("STRIPE_ACTION", "PENDING");
    await stripe.redirectToCheckout({ sessionId });
  }
};
