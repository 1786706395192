import React, { useState } from "react";

import { ConfirmPartnerOrgsData } from "api";
import { AccordionTheme, AlertTheme } from "assets";
import { ReviewImportFooter } from "components/tables/review-import-footer";
import ReviewOrganisationsTable from "components/tables/table-review-organisations";
import { useDashboard } from "contexts";
import { Accordion, Alert } from "flowbite-react";
import { Footer } from "layouts";
import { HiBan, HiCheck, HiCheckCircle, HiExclamation } from "react-icons/hi";
import { MdOutlineErrorOutline } from "react-icons/md";

export function ReviewOrganisations({
  partnerOrgsStatus,
  updatePartnerOrgsStatus = () => {},
  startPolling = () => {},
  stopPolling = () => {},
  setLoading = () => {},
  setIsJustFinished = () => {},
}) {
  const { dashboard } = useDashboard();

  const [err, setErr] = useState(null);
  const [showAlert, setShowAlert] = useState(true);

  const invalidOrgIds = partnerOrgsStatus?.progress?.reduce((result, org) => {
    if (org?.orgId?.split(" ")?.[0] === "INVALID_ORG") result += 1;
    return result;
  }, 0);

  const validOrgIds = partnerOrgsStatus?.progress?.reduce((result, org) => {
    if (org?.orgId?.split(" ")?.[0] === "PENDING") result += 1;
    return result;
  }, 0);

  const totalOrgIds = partnerOrgsStatus?.progress?.length;

  const hasIssues = invalidOrgIds > 0;

  const handleDismiss = () => {
    setShowAlert(false);
  };

  const handleDismissErr = () => {
    setErr(null);
  };

  const handleConfirmOrgs = async (orgs = []) => {
    setLoading(true);

    try {
      await ConfirmPartnerOrgsData({
        dashboard: dashboard?.route,
        data: orgs,
      });

      setIsJustFinished(true);
      sessionStorage.setItem(`${dashboard?.route}-just-finished`, "true");

      startPolling();
    } catch (error) {
      console.error(error);
      setLoading(false);
      stopPolling();
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8 self-stretch p-8">
        {showAlert && (
          <Alert
            color={hasIssues ? "yellow" : "green"}
            onDismiss={handleDismiss}
            icon={hasIssues ? HiExclamation : HiCheckCircle}
            theme={AlertTheme}
            additionalContent={
              hasIssues &&
              "Invalid organisation IDs have been found. Please review your results."
            }
          >
            <div className="alert-flowbite flex flex-row items-center gap-3">
              <div className="text-base font-semibold">
                Processing of file has been completed.
              </div>
            </div>
          </Alert>
        )}

        {err && (
          <Alert
            color={"red"}
            onDismiss={handleDismissErr}
            icon={MdOutlineErrorOutline}
            theme={AlertTheme}
            additionalContent={err.msg}
          />
        )}

        <ReviewOrganisationsSummary
          validOrgIds={validOrgIds}
          invalidOrgIds={invalidOrgIds}
          totalOrgIds={totalOrgIds}
        />

        <ReviewOrganisationsTable
          validOrgIds={validOrgIds}
          invalidOrgIds={invalidOrgIds}
          totalOrgIds={totalOrgIds}
          setLoading={setLoading}
          partnerOrgsStatus={partnerOrgsStatus}
          updatePartnerOrgsStatus={updatePartnerOrgsStatus}
          startPolling={startPolling}
        />
      </div>

      <div className="flex flex-col gap-0">
        <ReviewImportFooter
          validOrgIds={validOrgIds}
          invalidOrgIds={invalidOrgIds}
          totalOrgIds={totalOrgIds}
          handleConfirmOrgs={() =>
            handleConfirmOrgs(partnerOrgsStatus?.progress)
          }
        />
        <Footer />
      </div>
    </>
  );
}

function ReviewOrganisationsSummary({ validOrgIds, invalidOrgIds, totalOrgIds }) {
  return (
    <>
      <Accordion
        theme={AccordionTheme}
        alwaysOpen
        className="w-full laptop:max-w-[550px]"
      >
        <Accordion.Panel>
          <Accordion.Title className="bg-surface-white">
            <div className="text-primary text-lg font-bold">
              Review your import
            </div>
          </Accordion.Title>

          <Accordion.Content>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="text-sub text-sm font-bold">
                  Import Summary
                </span>

                {/* <div className="flex gap-1">
                  <span className="text-gray text-xs font-normal">
                    Import ID:
                  </span>{" "}
                  <span className="text-primary text-xs font-normal">
                    {partnerOrgsStatus?.id}
                  </span>
                </div> */}
              </div>

              <div className="border-primary text-sub flex justify-between border-b pb-4 text-sm">
                <span className="font-medium">Processed:</span>
                <span className="font-bold">{totalOrgIds}</span>
              </div>

              {/* <div className="border-primary flex justify-between border-b pb-4 text-sm text-green-500">
                <span className="font-medium">Successfully added</span>
                <span className="font-bold">{partnerOrgsStatus?.orgIds?.valid}</span>
              </div> */}

              <div className="flex flex-col gap-2 self-stretch">
                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center gap-2 font-medium">
                    <HiBan className="size-4 text-red-500" />
                    <span>Invalid organisation IDs</span>
                  </div>

                  <span>{invalidOrgIds}</span>
                </div>

                {/* <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center gap-2 font-medium">
                    <HiCheck className="size-4 text-green-500" />
                    <span>Existing organisation IDs</span>
                  </div>

                  <span>
                    {partnerOrgsStatus?.orgIds?.existing} {dashboard?.orgTitle?.toLowerCase()}
                  </span>
                </div> */}

                {/* <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center gap-2 font-medium">
                    <HiCheck className="size-4 text-green-500" />
                    <span>Duplicate organisation IDs</span>
                  </div>

                  <span>
                    {partnerOrgsStatus?.orgIds?.duplicate} {dashboard?.orgTitle?.toLowerCase()}
                  </span>
                </div> */}

                <div className="flex items-center justify-between">
                  <div className="flex flex-row items-center gap-2 font-medium">
                    <HiCheck className="size-4 text-green-500" />
                    <span>Valid organisation IDs</span>
                  </div>

                  <span>{validOrgIds}</span>
                </div>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </>
  );
}
