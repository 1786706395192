import { useEffect } from "react";

import { ProgressBarTheme } from "assets";
import { useDashboard } from "contexts";
import { Progress } from "flowbite-react";
import { Footer } from "layouts";

export function CheckOrganisations({
  partnerOrgsStatus = () => {},
  updatePartnerOrgsStatus = () => {},
  startPolling = () => {},
  stopPolling = () => {},
}) {
  const { dashboard } = useDashboard();

  const progress =
    Math.floor(
      partnerOrgsStatus?.progress?.length /
        partnerOrgsStatus?.partner?.uploadedCount,
    ) * 100;

  // Constantly poll for progress every second, once complete move to next step
  useEffect(() => {
    const interval = setInterval(() => updatePartnerOrgsStatus(), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-8 self-stretch p-8">
        <div className="flex flex-col gap-5 self-stretch">
          <span className="text-secondary text-lg font-bold">
            Checking Organisation IDs
          </span>

          <div className="text-primary text-sm">
            We are automatically checking the ABN's of your{" "}
            {dashboard?.orgPlural} to ensure they are correct. This process may
            take a while to finish. You can leave this page and you will be
            informed via text and an email will be sent to you at{" "}
            <span className="text-link font-bold">
              {partnerOrgsStatus?.user?.email}
            </span>{" "}
            once its ready.
          </div>
        </div>

        <div className="border-primary surface-primary flex flex-col gap-5 self-stretch rounded-lg border p-5">
          <span className="text-primary text-base font-semibold">
            Processing ABNs...
          </span>

          <div className="flex items-center gap-3 self-stretch">
            <div className="flex-grow">
              <Progress
                progress={progress}
                size="md"
                theme={ProgressBarTheme}
                color="blue"
              />
            </div>

            <span className="text-gray text-sm font-medium">
              Processing {progress}%
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
