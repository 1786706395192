import { API } from "aws-amplify";

export const getIssuedCertification = ({ id, ccid }) => {
  const myInit = {
    queryStringParameters: {
      item: id,
      ccid,
    },
  };
  return API.get("certification", `/issuedCertificate?item=${id}`, myInit);
};
