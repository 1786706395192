import { useEffect, useRef, useState } from "react";

import { HiCheck } from "react-icons/hi";
import { VscTriangleDown } from "react-icons/vsc";
import Flag from "react-world-flags";
import { ALLOWED_COUNTRIES } from "variables";

const COLOURS = {
  primary:
    "border-secondary surface-primary placeholder:text-icons focus:border-brand focus:ring-brand",
  failure:
    "border-red-500 bg-red-50 text-red-900 placeholder-red-400 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
};

const DISABLED_COLOURS = {
  primary: "surface-border-primary placeholder-transparent",
  failure: "border-red-200",
};

export function CountryInput({
  value = "",
  color = "primary",
  disabled = false,
  name = "",
  onChange = () => {},
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const containerRef = useRef(null);

  const toggleMenuOpen = () => setIsMenuOpen((prev) => !prev);

  const handleChange = (code) => {
    setSelectedOption(code);
    setIsMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleBlur = (event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.relatedTarget)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    onChange({
      target: {
        name,
        value: Object.entries(ALLOWED_COUNTRIES).find(
          ([_, { countryCode }]) => countryCode === selectedOption
        )?.[0],
      },
    });
  }, [selectedOption]);

  return (
    <div
      className="country-input relative flex w-full"
      data-testid="head-office-country-input"
      name={name}
      ref={containerRef}
      onBlur={handleBlur}
    >
      <div
        className={`flex w-full items-center self-stretch rounded-lg border ${COLOURS[color]} ${disabled ? ` ${DISABLED_COLOURS[color]}` : ""}`}
      >
        <button
          className={`flex items-center justify-center gap-1 self-stretch rounded-l-lg border-r border-inherit p-2 transition-all duration-200 ease-in-out enabled:hover:bg-gray-200 ${disabled ? "bg-gray-50" : "bg-gray-100"}`}
          disabled={disabled}
          type="button"
          onClick={toggleMenuOpen}
        >
          <Flag code={selectedOption} className="h-3.5 w-5" />
          <VscTriangleDown
            className={`size-2.5 text-gray-900 transition-transform duration-500 ease-in-out ${isMenuOpen ? "rotate-180" : "rotate-0"}`}
          />
        </button>

        <div
          className="flex gap-2.5 px-4 py-[13px] text-sm"
          data-testid="head-office-country-value"
        >
          {
            Object.entries(ALLOWED_COUNTRIES).find(
              ([_, { countryCode }]) => countryCode === selectedOption
            )?.[0]
          }
        </div>
      </div>

      <div
        className={`absolute left-0 right-0 top-[calc(100%+6px)] z-10 grid transition-all duration-500 ease-in-out ${isMenuOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]"}`}
      >
        <div
          className={`border-primary flex flex-col self-stretch overflow-hidden rounded-lg bg-white text-gray-700 shadow-md transition-[border-width] duration-500 ease-in-out ${isMenuOpen ? "border" : "border-0"}`}
        >
          {Object.entries(ALLOWED_COUNTRIES).map(
            ([label, { countryCode }], index) => (
              <CountryItem
                key={index}
                active={selectedOption === countryCode}
                code={countryCode}
                disabled={!isMenuOpen}
                label={label}
                handleChange={() => handleChange(countryCode)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

function CountryItem({
  active = false,
  code,
  disabled = false,
  label,
  handleChange = () => {},
}) {
  return (
    <button
      className="flex w-full items-center justify-between gap-2 self-stretch p-2 text-sm outline-none focus:text-brand focus:underline focus:outline-none enabled:hover:bg-gray-100"
      disabled={disabled}
      type="button"
      onClick={handleChange}
    >
      <Flag code={code} className="h-3.5 w-5" />
      <span className="w-full text-left">{label}</span>
      {active && <HiCheck className="size-4 text-brand" />}
    </button>
  );
}
