import { getIssuedCertification } from "api";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const key = "partner-certification";

export const useGetIssuedCertification = () => {
  return useQuery({
    queryKey: [key],
    queryFn: () => ({}),
  });
};

export const useSyncIssuedCertification = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (ccid) => (!!ccid ? getIssuedCertification({ ccid }) : {}),
    onSuccess: (verify) => {
      queryClient.setQueryData([key], () => verify);
    },
  });

  return async (ccid) => {
    await mutation.mutateAsync(ccid);
  };
};
