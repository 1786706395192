import React from "react";

import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

export default function TableTopSummary({
  validOrgIds,
  invalidOrgIds,
  totalOrgIds,
  isAllSelected,
  isMultipleSelected,
  handleEditOrgs = () => {},
  handleRemoveOrgs = () => {},
}) {
  return (
    <div className="items-left flex flex-col justify-between gap-5 border border-b-0 px-5 py-5 laptop:flex-row laptop:items-center laptop:py-3">
      <div className="items-left text-primary flex flex-row flex-wrap gap-3 font-semibold laptop:items-center">
        Count:{" "}
        <div className="items-left flex flex-row gap-2 laptop:items-center">
          <span className="font-normal">Valid ABNs </span> {validOrgIds}
        </div>
        <div className="items-left flex flex-row gap-2 laptop:items-center">
          <span className="font-normal">Invalid ABNs </span> {invalidOrgIds}
        </div>
        <div className="items-left flex flex-row gap-2 laptop:items-center">
          <span className="font-normal">Total ABNs</span> {totalOrgIds}
        </div>
      </div>

      {isMultipleSelected && (
        <div className="hidden flex-row items-center gap-1 desktop:flex">
          <button
            className="text-gray flex flex-row items-center gap-1 rounded-lg px-2 py-1
                    hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
            onClick={handleEditOrgs}
          >
            <HiOutlinePencil className="size-4" />
            <span className="text-sm font-medium">
              Edit {isAllSelected ? "All" : "Selected"}
            </span>
          </button>

          <button
            className="text-gray flex flex-row items-center gap-1 rounded-lg px-2 py-1
                    hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
            onClick={handleRemoveOrgs}
          >
            <HiOutlineTrash className="size-4" />
            <span className="text-sm font-medium">
              Remove {isAllSelected ? "All" : "Selected"}
            </span>
          </button>
        </div>
      )}

      {/* <Button
        leftIcon={<HiDownload className="size-4" />}
        label={"Export File"}
        variant={"blue"}
      /> */}
    </div>
  );
}
