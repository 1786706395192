import { useEffect } from "react";

import {
  Button,
  CountryInput,
  InternationalPhoneNumberInput,
  TextInput,
} from "components";
import { useDashboard } from "contexts";
import { useVerifyPartnerAccount } from "hooks";
import { HiCheck, HiPlus, HiX } from "react-icons/hi";
import {
  getCountryCodeFromPhoneNumber,
  sanitizeInputWithSpace,
  validateEmail,
  validateEmpty,
  validateInternationalPhoneNumber,
  validateOrgId,
} from "utils";
import { ALLOWED_COUNTRIES } from "variables";

export function ImportOrgsManual({
  orgs,
  setOrgs = () => {},
  setErrMsg = () => {},
  setWarnMsg = () => {},
  handleUploadOrgs = () => {},
}) {
  const partnerState = useVerifyPartnerAccount();
  const { dashboard } = useDashboard();

  const checkDuplicateOrgIds = (orgs, currentIndex, newOrgId) => {
    let isDuplicateOrgId = false;

    // Strip new org id here for processing
    newOrgId = newOrgId?.trim()?.replace(/\s/g, "");

    // Now add those orgids from the other editor orgs and the new orgid value coming in
    const allOrgIds = [
      ...orgs
        ?.map((org, index) => {
          if (index !== currentIndex) {
            return org?.orgId?.trim()?.replace(/\s/g, "");
          } else {
            return newOrgId;
          }
        })
        .filter((orgId) => orgId !== ""),
    ];

    // If orgid appears more than twice it is a duplicate
    orgs?.forEach((org, index) => {
      const checkOrgId = index !== currentIndex ? org?.orgId : newOrgId;

      if (allOrgIds.indexOf(checkOrgId) !== allOrgIds.lastIndexOf(checkOrgId)) {
        orgs[index].duplicateOrgId = true;
        isDuplicateOrgId = true;
      } else {
        orgs[index].duplicateOrgId = false;
      }
    });

    if (isDuplicateOrgId) {
      setWarnMsg(
        "You have entered a duplicate org id. Please check you have not already uploaded this org id."
      );
    } else {
      setWarnMsg(null);
    }
  };

  const handleAddNew = () => {
    const country = Object.entries(ALLOWED_COUNTRIES).find(
      ([key, value]) =>
        value?.countryCode === partnerState?.data?.organisation?.country
    )?.[0];
    const govIdName = ALLOWED_COUNTRIES[country]?.govIdName;
    setOrgs((prevOrgs) => [
      ...prevOrgs,
      {
        country,
        govIdName,
        name: "",
        orgId: "",
        email: "",
        mobile: "",
        error: {
          country: false,
          name: false,
          orgId: false,
          email: false,
          mobile: false,
        },
        duplicate: false,
      },
    ]);
  };

  const handleRemove = (target) => {
    if (orgs?.length === 1) {
      const country = Object.entries(ALLOWED_COUNTRIES).find(
        ([key, value]) =>
          value?.countryCode === partnerState?.data?.organisation?.country
      )?.[0];
      const govIdName = ALLOWED_COUNTRIES[country]?.govIdName;
      setOrgs([
        {
          country,
          govIdName,
          name: "",
          orgId: "",
          email: "",
          mobile: "",
          error: {
            country: false,
            name: false,
            orgId: false,
            email: false,
            mobile: false,
          },
          duplicate: false,
        },
      ]);
      return;
    }
    setOrgs((prev) => prev.filter((_, index) => index !== target));
  };

  const handleInput = (event, index) => {
    let newOrgs = [...orgs];
    let newOrg = newOrgs[index];

    if (event.target.name === "country") {
      newOrgs[index] = {
        ...newOrg,
        country: event.target.value,
        govIdName: ALLOWED_COUNTRIES[event.target.value]?.govIdName,
        error: {
          ...newOrg?.error,
          country: false,
        },
      };
    } else {
      newOrgs[index] = {
        ...newOrg,
        [event.target.name]:
          event.target.name === "orgId"
            ? event.target.value.replace(/ /g, "")
            : event.target.value,
        error: {
          ...newOrg?.error,
          [event.target.name]: false,
        },
      };
    }

    checkDuplicateOrgIds(newOrgs, index, event?.target?.value);
    setOrgs(newOrgs);
  };

  const handlePaste = (event, index) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text").replace(/\s/g, "");
    if (/[0-9\s]/.test(paste)) {
      const newOrgs = [...orgs];

      const input = event.target;
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      const originalValue = newOrgs[index][event.target.name];
      const value =
        originalValue.substring(0, startPos) +
        paste +
        originalValue.substring(endPos);
      input.setSelectionRange(startPos + paste.length, startPos + paste.length);

      newOrgs[index][event.target.name] = value;
      newOrgs[index].error[event.target.name] = false;
      newOrgs[index].duplicate = false;

      setOrgs(newOrgs);
    }
  };

  const handleSubmit = async () => {
    let submitOrgs = [
      ...orgs?.map((org) => ({
        ...org,
        name: org?.name?.trim(),
        orgId: org?.orgId?.replace(/\s/g, ""),
        email: org?.email?.trim(),
        mobile: org?.mobile?.replace(/\s/g, ""),
      })),
    ];

    let isInvalidField = false;
    let isOwnOrgId = false;

    submitOrgs.forEach((org) => {
      if (!validateEmpty(org?.name)) {
        org.error.name = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.orgId) || !validateOrgId(org?.orgId)) {
        org.error.orgId = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.country)) {
        org.error.country = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.email) || !validateEmail(org?.email)) {
        org.error.email = true;
        isInvalidField = true;
      }

      if (
        !validateEmpty(org?.mobile) &&
        !validateInternationalPhoneNumber(
          org?.mobile,
          ALLOWED_COUNTRIES[org?.country]?.countryCode
        )
      ) {
        org.error.mobile = true;
        isInvalidField = true;
      }

      if (
        sanitizeInputWithSpace(org?.orgId) ===
        partnerState?.data?.partner?.orgId
      ) {
        org.error.orgId = true;
        isOwnOrgId = true;
      }
    });

    if (isInvalidField) {
      setErrMsg("You have entered an invalid field. Please check again.");
      setOrgs(submitOrgs);
      return;
    } else if (isOwnOrgId) {
      setErrMsg(
        `You cannot add your own account as a ${dashboard?.orgSingle}. To access your account's dashboard, switch to Certification from the navigation menu.`
      );
      setOrgs(submitOrgs);
      return;
    }

    submitOrgs = submitOrgs.map((org) => ({
      ...org,
      country: ALLOWED_COUNTRIES[org?.country]?.countryCode,
    }));

    handleUploadOrgs(submitOrgs);
  };

  useEffect(() => {
    if (
      !orgs?.some((org) => Object.values(org.error).some((error) => !!error))
    ) {
      setErrMsg(null);
    }
  }, [orgs]);

  return (
    <>
      {!!partnerState?.data?.organisation ? (
        <>
          <div className="flex flex-col gap-4 self-stretch">
            <div className="text-primary hidden text-sm font-medium laptop:flex laptop:gap-4">
              <span className="invisible">{orgs?.length}.</span>
              <span className="ml-1 w-full">Country</span>
              <span className="ml-1 w-full">Organisation ID</span>
              <span className="ml-1 w-full">{dashboard?.orgTitleSingle}</span>
              <span className="ml-1 w-full">Email</span>
              <span className="ml-1 w-full">Mobile</span>
              <span className="size-5 shrink-0"></span>
            </div>

            {orgs?.map((org, index) => (
              <ImportOrgsManualRow
                partnerState={partnerState}
                key={index}
                item={org}
                index={index}
                handleInput={(event) => handleInput(event, index)}
                handlePaste={handlePaste}
                handleRemove={handleRemove}
                handleSubmit={handleSubmit}
              />
            ))}

            <button
              className="btn-text flex w-fit flex-row items-center gap-1 self-stretch rounded px-1 
                      focus-visible:ring-2 focus-visible:ring-blue-300 focus-visible:ring-offset-2 enabled:hover:opacity-80"
              onClick={handleAddNew}
            >
              <HiPlus className="h-3.5 w-3.5" />
              <span className="text-sm font-semibold">Add New</span>
            </button>
          </div>

          <section>
            <Button
              label="Submit"
              leftIcon={<HiCheck className="size-4" />}
              variant="blue"
              onClick={handleSubmit}
            />
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function ImportOrgsManualRow({
  partnerState,
  item,
  index,
  handleInput = () => {},
  handlePaste = () => {},
  handleRemove = () => {},
  handleSubmit = () => {},
}) {
  const { dashboard } = useDashboard();

  return (
    <form
      className="flex flex-col items-start gap-4 laptop:flex-row laptop:items-center"
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="text-primary flex flex-row gap-1 text-sm font-medium">
        <span className="flex laptop:hidden">{dashboard?.orgTitleSingle}</span>{" "}
        {index + 1}.
      </div>

      <CountryInput
        data-testid="country-input"
        name="country"
        color={!!item?.error?.["country"] ? "failure" : "gray"}
        value={ALLOWED_COUNTRIES[item?.country]?.countryCode}
        onChange={(event) => handleInput(event, index)}
        error={item?.error?.["country"]}
        disbaled
      />

      <TextInput
        color={
          !!item?.error?.["orgId"]
            ? "failure"
            : !!item?.duplicateOrgId
              ? "warning"
              : "gray"
        }
        name="orgId"
        placeholder={`Enter ${dashboard?.orgSingle} ${item?.govIdName}`}
        required
        type="tel"
        value={item?.orgId}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <TextInput
        color={!!item?.error?.["name"] ? "failure" : "gray"}
        name="name"
        placeholder={`Enter ${dashboard?.orgTitleSingle} name`}
        required
        type="text"
        value={item?.name}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <TextInput
        color={!!item?.error?.["email"] ? "failure" : "gray"}
        name="email"
        placeholder={`Enter ${dashboard?.orgSingle} email`}
        required
        type="email"
        className={"w-full"}
        value={item?.email}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <InternationalPhoneNumberInput
        autocomplete="off"
        color={!!item?.error?.["mobile"] ? "failure" : "primary"}
        initialCountry={getCountryCodeFromPhoneNumber(item?.mobile)}
        initialValue={item?.mobile}
        id={`mobile-${index}`}
        label="Optional"
        name="mobile"
        placeholder={`Enter ${dashboard?.orgSingle} mobile`}
        required
        onInput={(event) => handleInput(event, index)}
      />

      <button
        className="rounded focus:ring-2 focus:ring-gray-400 enabled:hover:bg-gray-200"
        type="button"
        onClick={() => handleRemove(index)}
      >
        <HiX className="text-gray size-5" />
      </button>
    </form>
  );
}
