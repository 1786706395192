import { Button } from "components/buttons";
import { useDashboard } from "contexts";
import { HiCheck } from "react-icons/hi";

export function ReviewImportFooter({
  validOrgIds,
  invalidOrgIds,
  totalOrgIds,
  handleConfirmOrgs = () => {},
}) {
  const { dashboard } = useDashboard();

  return (
    <>
      <div className="surface-db-stepper flex flex-col gap-4 p-8">
        {!!invalidOrgIds ? (
          <>
            <div>
              We identified {invalidOrgIds} invalid ABN{invalidOrgIds !== 1 && "s"}.
              Please remove or edit {invalidOrgIds !== 1 ? "these" : "this"}{" "}
              {invalidOrgIds !== 1 ? dashboard?.orgPlural : dashboard?.orgSingle}{" "}
              to proceed.
            </div>
            {/* <div>
              You can also select all invalid ABN entries and export them for re-adding of {dashboard?.orgPlural} at a later time.
            </div> */}
          </>
        ) : (
          <>
            <div>
              We have successfully imported {validOrgIds} out of {totalOrgIds}{" "}
              {totalOrgIds !== 1 ? dashboard?.orgPlural : dashboard?.orgSingle}.
              View all your{" "}
              {totalOrgIds !== 1 ? dashboard?.orgPlural : dashboard?.orgSingle} in
              the {dashboard?.orgTitle}{" "}
              {/* <Link
                to={ROUTE.ORGANISATIONS}
                className="font-medium hover:underline"
              >
                {dashboard?.orgTitle}
              </Link>{" "} */}
              page.
            </div>
          </>
        )}
        <div className="mt-3 w-fit">
          <Button
            disabled={!!invalidOrgIds}
            leftIcon={<HiCheck className="size-4" />}
            label={"Confirm"}
            variant={"blue"}
            onClick={handleConfirmOrgs}
          />
        </div>
      </div>
    </>
  );
}
