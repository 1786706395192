import React, { useEffect, useState } from "react";

import { updatePartnerDetails } from "api";
import { Button } from "components/buttons";
import { TextInput } from "components/inputs";
import { OverlaySpinner } from "components/spinners";
import { useDashboard } from "contexts";
import { Alert, Modal } from "flowbite-react";
import { useSyncVerifyPartnerAccount } from "hooks";
import { HiCheck, HiExclamation } from "react-icons/hi";
import { handleEnterKeyDownSubmission } from "utils";

export function UpdateOrgNameModal({
  isOpen,
  orgId,
  orgName,
  handleCloseModal = () => {},
}) {
  const syncPartnerState = useSyncVerifyPartnerAccount();

  const { dashboard } = useDashboard();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState({});
  const [updatedOrgName, setUpdatedOrgName] = useState("");

  const handleDismissErr = () => {
    setErr({});
  };

  const handleInput = (event) => {
    setUpdatedOrgName(event.target.value);
    setErr({});
  };

  const handleSubmit = async () => {
    // Validate input
    if (!updatedOrgName) {
      setErr({
        type: "empty",
        msg: "You must enter a name",
      });
      return;
    }

    setIsLoading(true);

    let result = await updatePartnerDetails({
      dashboardCustomer: {
        dashboard: dashboard.route,
        orgId: orgId,
        name: updatedOrgName,
      },
    });

    await syncPartnerState();

    setIsLoading(false);
    handleCloseModal(true);
  };

  useEffect(() => {
    setUpdatedOrgName(orgName);
    setErr({});
  }, [isOpen]);

  return (
    <Modal
      show={isOpen}
      size="2xl"
      position="center"
      onClose={handleCloseModal}
    >
      <Modal.Header>Update {dashboard.orgTitleSingle}</Modal.Header>
      <Modal.Body>
        {!!isLoading && <OverlaySpinner />}
        <div className="flex flex-grow flex-col gap-4 self-stretch px-1">
          {!!err.msg && (
            <Alert
              color="failure"
              icon={HiExclamation}
              onDismiss={handleDismissErr}
            >
              {err.msg}
            </Alert>
          )}

          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">
              {dashboard.orgTitleSingle} Name
            </span>
            <TextInput
              className="w-full"
              color="gray"
              required
              type="text"
              value={updatedOrgName}
              onInput={handleInput}
              onKeyDown={(event) =>
                handleEnterKeyDownSubmission(event, handleSubmit)
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          customClasses="w-full laptop:w-fit"
          label="Submit"
          leftIcon={<HiCheck className="size-4" />}
          variant="blue"
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}
