// Landing app
export const DEV_LANDING_HOSTNAME = "cmxdev.cybercert.ai";
export const DEMO_LANDING_HOSTNAME = "demo.cybercert.ai";
export const LANDING_HOSTNAME = "cybercert.ai";
export const STAGING_LANDING_HOSTNAME = "staging.cybercert.ai";
export const WWW_LANDING_HOSTNAME = "www.cybercert.ai";
// Partners MSP
export const DEV_PARTNERS_MSP_HOSTNAME = "dev.partners.cybercert.ai";
export const PARTNERS_MSP_HOSTNAME = "partners.cybercert.ai";
export const STAGING_PARTNERS_MSP_HOSTNAME = "staging.partners.cybercert.ai";
// Dashboard app
export const DEV_DASHBOARD_HOSTNAME = "dev.dashboard.cybercert.ai";
export const DASHBOARD_HOSTNAME = "dashboard.cybercert.ai";
export const STAGING_DASHBOARD_HOSTNAME = "staging.dashboard.cybercert.ai";
export const PARTNER_DASHBOARD_HOSTNAME = "partner.dashboard.cybercert.ai";
// Certification app
export const DEV_CERTIFICATION_HOSTNAME = "dev.certification.cybercert.ai";
export const BETA_CERTIFICATION_HOSTNAME = "beta.certification.cybercert.ai";
export const DEMO_CERTIFICATION_HOSTNAME = "demo.certification.cybercert.ai";
export const CERTIFICATION_HOSTNAME = "certification.cybercert.ai";
export const STAGING_CERTIFICATION_HOSTNAME =
  "staging.certification.cybercert.ai";
// ADHA landing
export const DEV_ADHA_LANDING_HOSTNAME = "dev.health.cybercert.ai";
export const ADHA_LANDING_HOSTNAME = "health.cybercert.ai";
export const STAGING_ADHA_LANDING_HOSTNAME = "staging.health.cybercert.ai";

export const isLocalHost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.match(
        /^172(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.match(
        /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const isStagingHost = () =>
  Boolean(
    window.location.hostname === STAGING_CERTIFICATION_HOSTNAME ||
      window.location.hostname === STAGING_LANDING_HOSTNAME ||
      window.location.hostname === STAGING_DASHBOARD_HOSTNAME ||
      window.location.hostname === STAGING_PARTNERS_MSP_HOSTNAME ||
      window.location.hostname === STAGING_ADHA_LANDING_HOSTNAME
  );

export const isDevHost = () =>
  Boolean(
    window.location.hostname === DEV_CERTIFICATION_HOSTNAME ||
      window.location.hostname === DEV_LANDING_HOSTNAME ||
      window.location.hostname === DEV_DASHBOARD_HOSTNAME ||
      window.location.hostname === DEV_PARTNERS_MSP_HOSTNAME ||
      window.location.hostname === DEV_ADHA_LANDING_HOSTNAME
  );

export const isBetaHost = () =>
  Boolean(window.location.hostname === BETA_CERTIFICATION_HOSTNAME);

export const isDemoHost = () =>
  Boolean(
    window.location.hostname === DEMO_CERTIFICATION_HOSTNAME ||
      window.location.hostname === DEMO_LANDING_HOSTNAME
  );

export const isProdHost = () =>
  Boolean(
    window.location.hostname === CERTIFICATION_HOSTNAME ||
      window.location.hostname === LANDING_HOSTNAME ||
      window.location.hostname === WWW_LANDING_HOSTNAME ||
      window.location.hostname === PARTNERS_MSP_HOSTNAME ||
      window.location.hostname === DASHBOARD_HOSTNAME ||
      window.location.hostname === ADHA_LANDING_HOSTNAME
  );

export const isDevPartnersMspHost = () =>
  Boolean(window.location.hostname === DEV_PARTNERS_MSP_HOSTNAME);

export const isProdPartnersMspHost = () =>
  Boolean(window.location.hostname === PARTNERS_MSP_HOSTNAME);

export const OFFICIAL_DOMAIN = "cybercert.ai";

export const LOCAL_LANDING = "http://localhost:3000";
export const LOCAL_DASHBOARD = "http://localhost:3001";
export const LOCAL_APP = "http://localhost:3002";
export const LOCAL_PARTNER_LANDING = "http://localhost:3003";
export const LOCAL_ADHA_LANDING = "http://localhost:3004";

export const getLandingPage = () => {
  if (isLocalHost()) {
    return LOCAL_LANDING;
  } else if (isDevHost()) {
    return `https://${DEV_LANDING_HOSTNAME}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_LANDING_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${LANDING_HOSTNAME}`;
  }
};

export const getDashboardPage = () => {
  if (isLocalHost()) {
    return LOCAL_DASHBOARD;
  } else if (isDevHost()) {
    return `https://${DEV_DASHBOARD_HOSTNAME}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_DASHBOARD_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${DASHBOARD_HOSTNAME}`;
  }
};

export const getAppPage = () => {
  if (isLocalHost()) {
    return LOCAL_APP;
  } else if (isDevHost()) {
    return `https://${DEV_CERTIFICATION_HOSTNAME}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_CERTIFICATION_HOSTNAME}`;
  } else if (isProdHost()) {
    return `https://${CERTIFICATION_HOSTNAME}`;
  }
};

export const getAppDestination = (dashboard = true) => {
  if (isLocalHost()) {
    return dashboard ? LOCAL_DASHBOARD : LOCAL_APP;
  } else if (isDevHost()) {
    return dashboard
      ? `https://${DEV_DASHBOARD_HOSTNAME}`
      : `https://${DEV_CERTIFICATION_HOSTNAME}`;
  } else if (isStagingHost()) {
    return dashboard
      ? `https://${STAGING_DASHBOARD_HOSTNAME}`
      : `https://${STAGING_CERTIFICATION_HOSTNAME}`;
  } else if (isProdHost()) {
    return dashboard
      ? `https://${DASHBOARD_HOSTNAME}`
      : `https://${CERTIFICATION_HOSTNAME}`;
  } else if (isDemoHost()) {
    return `https://${DEMO_CERTIFICATION_HOSTNAME}`;
  } else {
    return LOCAL_LANDING;
  }
};

/**
 * Get the landing destination
 */
export const getLandingDestination = () => {
  if (isDevHost()) {
    return `https://${DEV_LANDING_HOSTNAME}${window.location.pathname}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_LANDING_HOSTNAME}${window.location.pathname}`;
  } else if (isProdHost()) {
    return `https://${LANDING_HOSTNAME}${window.location.pathname}`;
  } else {
    return `${LOCAL_LANDING}${window.location.pathname}`;
  }
};

/**
 * Get the partners msp destination
 */
export const getPartnersMspDestination = () => {
  if (isDevHost()) {
    return `https://${DEV_PARTNERS_MSP_HOSTNAME}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_PARTNERS_MSP_HOSTNAME}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  } else if (isProdHost()) {
    return `https://${PARTNERS_MSP_HOSTNAME}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  } else {
    return `${LOCAL_PARTNER_LANDING}${getPartnersMspPathnameFromLanding(window.location.pathname)}`;
  }
};

const getPartnersMspPathnameFromLanding = (path) => {
  // return path.replace("/partners/", "").replace("partner-", "");
  if (path.includes("/partners/msp")) {
    return "/msp";
  } else if (path.includes("/partner-signup")) {
    return "/signup";
  } else if (path.includes("/partner-form")) {
    return "/signup";
  } else if (path.includes("/partner-register")) {
    return "/register";
  } else if (path.includes("/partner-login")) {
    return "/login";
  } else {
    return path;
  }
};

/**
 * Get the ADHA landing destination
 */
export const getAdhaLandingDestination = () => {
  if (isDevHost()) {
    return `https://${DEV_ADHA_LANDING_HOSTNAME}${window.location.pathname}`;
  } else if (isStagingHost()) {
    return `https://${STAGING_ADHA_LANDING_HOSTNAME}${window.location.pathname}`;
  } else if (isProdHost()) {
    return `https://${ADHA_LANDING_HOSTNAME}${window.location.pathname}`;
  } else {
    return `${LOCAL_ADHA_LANDING}${window.location.pathname}`;
  }
};
