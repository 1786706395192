import { useEffect, useState } from "react";

import { generateGAPAssessmentResult, verifyGAPAssessmentToken } from "api";
import { placeholderLogo } from "assets";
import { LoadingSpinner } from "components";
import { Navbar, SidebarAssessmentProcess } from "layouts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFlatGAPAssessmentQuestions } from "services";
import { scrollToTop } from "utils";
import { ROUTES } from "variables";

import { FinishAssessment } from "./finish";
import { ReviewResponses } from "./review";
import { AssessmentSections } from "./sections";

export function AssessmentProgress({}) {
  const { state } = useLocation();

  const params = useParams();
  const navigate = useNavigate();
  const [govIdName, setGovIdName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [assessment, setAssessment] = useState();
  const [assessmentResult, setAssessmentResult] = useState();
  const [organisation, setOrganisation] = useState();
  const [partner, setPartner] = useState();
  const [dashboard, setDashboard] = useState();
  const [questions, setQuestions] = useState([]);
  const [err, setErr] = useState();
  const [fullQuestions, setFullQuestions] = useState();

  const partnerLogoL = !partner?.logo?.includes("favicon")
    ? partner?.logo
    : null;

  const allQuestionsAnswered = questions?.every((section) =>
    section?.every((question) => !!question?.value)
  );

  const stepTitles = [
    // ...questions?.map((_, index) => `Section ${index + 1}`),
    ...questions?.map((_, index) => `Questions`),
    "Review",
    "Finish",
  ];

  const getSteps = () => {
    return stepTitles.map((_, index) => {
      if (index < currentStep || currentStep >= stepTitles.length - 1) {
        return "completed";
      } else if (index === currentStep) {
        return "inprogress";
      } else {
        return "todo";
      }
    });
  };

  const handleChangeStep = (step) => {
    setErr(null);
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setErr(null);
    setCurrentStep((step) => Math.min(step + 1, stepTitles?.length - 1));
  };

  const handlePrevStep = () => {
    setErr(null);
    setCurrentStep((step) => Math.max(step - 1, 0));
  };

  const setup = async () => {
    setErr(null);
    if (!!params?.token && !!params?.distribution) {
      const result = await generateGAPAssessmentResult({
        session: params?.token,
        assessmentToken: params?.distribution,
        preview: state?.preview,
      });

      const verify = await verifyGAPAssessmentToken({
        token: params?.distribution,
      });
      setPartner(verify?.partner);

      if (!result?.assessment) {
        window.location.href = ROUTES.GAP;
      } else {
        const flatQuestions = [
          getFlatGAPAssessmentQuestions(result?.questions),
        ];
        setGovIdName(JSON.parse(result?.organisation?.govId || "{}")?.identifiers?.[0]?.type);
        setQuestions(flatQuestions);
        setFullQuestions(result?.questions);
        setAssessment(result?.assessment);
        setOrganisation(result?.organisation);
        setDashboard(result?.assessment?.dashboard);
        setAssessmentResult(result?.assessmentResult);
      }
    }

    setIsLoading(false);
  };

  const handleAnswer = ({ id, value, section, question }) => {
    setErr(null);
    let updatedAnswers = questions;
    updatedAnswers = updatedAnswers.map((section) =>
      section.map((q) => {
        if (q?.question === question) {
          return {
            ...q,
            value,
          };
        } else {
          return q;
        }
      })
    );
    setQuestions(updatedAnswers);
  };

  const saveAndExit = async () => {
    setErr(null);
    await save();
    navigate(ROUTES.ASSESSMENT_LINK.replace(":token", params?.distribution));
  };

  const handleSubmit = async () => {
    setErr(null);
    setIsLoading(true);
    if (currentStep === stepTitles?.length - 2 && allQuestionsAnswered) {
      const res = await save(true);
      setAssessment(res?.assessment);
      setAssessmentResult(res?.assessmentResult);
      handleNextStep();
    } else if (currentStep === stepTitles?.length - 1 && allQuestionsAnswered) {
    } else if (allQuestionsAnswered) {
      setCurrentStep(stepTitles?.length - 2);
    } else {
      setErr("Please provide answers for all questions before submitting.");
    }
    setIsLoading(false);
  };

  const save = async (submit = false) => {
    setErr(null);
    let updatedFullQuestions = fullQuestions.map((section) => {
      return section.map((q) => {
        let updatedQ = q;
        questions?.forEach((s) => {
          s?.forEach((question) => {
            if (question?.question === q?.question) {
              updatedQ = {
                ...updatedQ,
                value: question?.value,
              };
            }
          });
        });
        return updatedQ;
      });
    });
    setFullQuestions(updatedFullQuestions);
    return await generateGAPAssessmentResult({
      submit,
      session: params?.token,
      assessmentToken: params?.distribution,
      answers: updatedFullQuestions
        ?.flatMap((q) => q)
        ?.map((question) => ({
          id: question?.id,
          requirement: question?.requirement,
          value: question?.value,
        })),
      preview: state?.preview,
    });
  };

  const returnToDashboard = () => {};

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => scrollToTop(), [currentStep]);

  return !!isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Navbar
        assessmentFn={returnToDashboard}
        assessmentId={assessment?.id}
        assessmentPreview={state?.preview}
        assessmentToken={params?.distribution}
        assessmentUser={assessmentResult?.verifiedData}
        eligibilityCheck={true}
        primeLogo={partner?.logoUrl || partner?.faviconUrl || placeholderLogo}
        primeLogoL={partnerLogoL}
        orgName={partner?.name || "CAP Partner"}
        variant="renderAssessmentNav"
      />

      <div className="surface-primary flex flex-grow flex-col self-stretch laptop:flex-row">
        <SidebarAssessmentProcess
          govIdName={govIdName}
          assessmentResult={assessmentResult}
          organisation={organisation}
          partner={partner}
          steps={getSteps()}
          stepTitles={stepTitles}
          title="Steps"
        />

        {
          [
            ...questions?.map((section, index) => (
              <AssessmentSections
                currentStep={currentStep}
                questions={questions}
                section={index + 1}
                selectedDashboard={dashboard}
                save={saveAndExit}
                submit={handleSubmit}
                error={err}
                preview={state?.preview}
                disabledSubmit={!allQuestionsAnswered}
                handleAnswer={handleAnswer}
                handleChangeStep={handleChangeStep}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
              />
            )),
            <ReviewResponses
              questions={questions}
              selectedDashboard={dashboard}
              error={err}
              disabledSubmit={!allQuestionsAnswered}
              handlePrevStep={handlePrevStep}
              handleSubmit={handleSubmit}
            />,
            <FinishAssessment
              assessment={assessment}
              organisation={organisation}
              assessmentResult={assessmentResult}
              selectedDashboard={dashboard}
              error={err}
            />,
          ][currentStep]
        }
      </div>
    </>
  );
}
