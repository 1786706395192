import { useEffect, useRef, useState } from "react";

import { TextInputTheme } from "assets";
import { Button } from "components";
import { TextInput } from "flowbite-react";
import { HiArrowLeft, HiCheck, HiXCircle } from "react-icons/hi";
import { fetchImageAsBase64 } from "services";
import { ALLOWED_COUNTRIES } from "variables";

export function OrganizationOnboarding({
  input,
  partner,
  organisation,
  setInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [favicon, setFavicon] = useState();
  const [logo, setLogo] = useState();
  const [showFaviconPreview, setShowFaviconPreview] = useState(false);
  const [showLogoPreview, setShowLogoPreview] = useState(false);
  const [err, setErr] = useState(null);
  const fileRef = useRef();

  useState(() => {
    if (!!partner) {
      setupImages();
    }
  }, [partner]);

  useEffect(() => {
    setInput({
      ...input,
      favicon,
      logo,
    });
  }, [favicon, logo]);

  async function setupImages() {
    const partnerLogo = partner?.logoUrl;
    const partnerFavicon = partner?.faviconUrl;
    if (!!partnerLogo && !partnerLogo.includes("favicon")) {
      // large logo
      const image = await fetchImageAsBase64(partnerLogo);
      if (!!image) {
        setLogo(image);
        setShowLogoPreview(true);
      }
    }
    if (!!partnerFavicon) {
      const image = await fetchImageAsBase64(partnerFavicon);
      if (!!image) {
        setFavicon(image);
        setShowFaviconPreview(true);
      }
    }
  }

  const handleInput = (event) => {
    setInput({
      ...input,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleFile = async (event, type) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      if (type === "favicon") {
        setFavicon(reader.result);
        setShowFaviconPreview(true);
      } else if (type === "logo") {
        setLogo(reader.result);
        setShowLogoPreview(true);
      }
    };
    reader.readAsDataURL(file);
  };

  const upload = (type) => {
    document.getElementById(`upload-org-${type}-hidden`).click();
  };

  const removePreview = (type) => {
    if (type === "favicon") {
      setFavicon(null);
      setShowFaviconPreview(false);
    } else if (type === "logo") {
      setLogo(null);
      setShowLogoPreview(false);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="text-primary w-fit text-lg font-bold">
          About Your Organisation
        </div>

        <div className="text-gray w-fit text-sm">
          Please confirm your organisation details.
        </div>
      </div>

      <div className="flex w-full flex-col gap-8 self-stretch">
        <div className="flex flex-col gap-4 self-stretch laptop:gap-8">
          <div className="flex flex-col gap-8 self-stretch text-sm font-medium">
            <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
              <div className="flex w-full flex-col gap-2 self-stretch">
                {ALLOWED_COUNTRIES[organisation?.countryName]?.govIdName}
                <TextInput
                  name="orgId"
                  type="text"
                  value={partner?.orgId}
                  disabled
                  required
                  shadow
                  // onChange={handleInput}
                  // onKeyPress={filterNumberAndSpaceKeyPress}
                  // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                  // onPaste={filterNumberAndSpacePaste}
                  color={!!err ? "failure" : "gray"}
                  theme={TextInputTheme}
                />
              </div>

              <div className="flex w-full flex-col gap-2 self-stretch">
                Entity Name
                <TextInput
                  name="entity"
                  type="text"
                  value={organisation?.name}
                  disabled
                  required
                  shadow
                  // onChange={handleInput}
                  // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                  color={!!err ? "failure" : "gray"}
                  theme={TextInputTheme}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-2 self-stretch">
              Entity Type
              <TextInput
                name="sector"
                type="text"
                value={
                  organisation?.type
                    ? organisation.type.replace(/\b\w/g, (c) => c.toUpperCase())
                    : ""
                }
                disabled
                required
                shadow
                // onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
            <div className="flex w-full flex-col gap-2 self-stretch font-semibold">
              Location
              <TextInput
                name="location"
                type="text"
                value={`${organisation?.addressState}, ${organisation?.addressPostcode}`}
                disabled
                required
                shadow
                // onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-2 self-stretch">
              Preferred Display Name
              <TextInput
                name="name"
                type="text"
                defaultValue={partner?.name}
                placeholder="Enter your preferred organisation display name"
                required
                shadow
                onChange={handleInput}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
            <div className="flex w-full flex-col gap-2 self-stretch font-semibold">
              Website
              <TextInput
                name="website"
                type="text"
                defaultValue={partner?.website}
                placeholder="Enter your website"
                required
                shadow
                onChange={handleInput}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center gap-8 laptop:flex-row">
          <div className="flex w-full flex-col gap-5">
            <div className="flex w-fit flex-col self-stretch">
              <div className="text-primary text-lg font-bold">
                Upload your logo
              </div>
              <div className="text-gray text-sm">
                Supported formats are PNG and JPG.
              </div>
              <div className="text-gray text-sm">
                Dimensions: 500px by 60px max.
              </div>
            </div>

            <div className="relative flex w-fit items-center gap-3 self-stretch">
              {!!logo && showLogoPreview && (
                <div className="border-surface-primary m-0 flex h-fit w-fit items-center justify-center overflow-hidden rounded-lg border object-contain p-0">
                  <img
                    src={logo}
                    alt="uploaded logo"
                    className="h-15 w-fit overflow-hidden p-2"
                  />
                </div>
              )}

              <input
                id="upload-org-logo-hidden"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={(event) => handleFile(event, "logo")}
                hidden
              />

              {showLogoPreview && (
                <RemoveButton onClick={() => removePreview("logo")} />
              )}

              {!showLogoPreview && (
                <Button
                  label={"Upload"}
                  labelStyles={"font-semibold text-sm"}
                  variant={"white"}
                  onClick={() => upload("logo")}
                />
              )}
            </div>
          </div>

          <div className="flex w-full flex-col gap-5">
            <div className="flex w-fit flex-col self-stretch">
              <div className="text-primary text-lg font-bold">
                Add a favicon
              </div>
              <div className="text-gray text-sm">
                Supported formats are PNG and JPG.
              </div>
              <div className="text-gray text-sm">
                Dimensions: 48px by 48px max.
              </div>
            </div>

            <div className="relative flex w-fit items-center gap-3 self-stretch">
              {!!favicon && showFaviconPreview && (
                <div className="border-surface-primary m-0 flex aspect-square w-[48px] items-center justify-center overflow-hidden rounded-full border object-cover p-0">
                  <img
                    src={favicon}
                    alt="uploaded favicon"
                    className="aspect-square w-full scale-110 overflow-hidden object-cover"
                  />
                </div>
              )}

              <input
                id="upload-org-favicon-hidden"
                type="file"
                accept="image/*"
                multiple={false}
                onChange={(event) => handleFile(event, "favicon")}
                hidden
              />

              {showFaviconPreview && (
                <RemoveButton onClick={() => removePreview("favicon")} />
              )}

              {!showFaviconPreview && (
                <Button
                  label={"Upload"}
                  labelStyles={"font-semibold text-sm"}
                  variant={"white"}
                  onClick={() => upload("favicon")}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-3 self-stretch">
          <Button
            customClasses={`w-full laptop:w-fit`}
            leftIcon={<HiArrowLeft className="size-4" />}
            label={"Back"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handlePrevStep}
          />

          <Button
            customClasses={`w-full laptop:w-fit`}
            leftIcon={<HiCheck className="size-4" />}
            label={"Next Step"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handleNextStep}
          />
        </div>
      </div>
    </>
  );
}

function RemoveButton({ onClick }) {
  return (
    <button
      className="absolute -right-3 -top-2 mr-1 mt-1 rounded-full bg-gray-100 text-gray-400 transition duration-300 hover:bg-gray-200 hover:text-gray-500"
      onClick={onClick}
    >
      <HiXCircle className="size-5" />
    </button>
  );
}
