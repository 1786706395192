import { pushLoginEvent, updateUserMFA, verifyPartnerAccountPublic } from "api";
import { Auth } from "aws-amplify";
import { getLandingPage } from "services/utils";

import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

/**
 * Check if user is login or not
 * Set the state accordingly if provided
 * @param {fn} setLoggedIn
 */
const checkLoginState = () =>
  getCurrentAuthUserInfo()
    .then((sess) => {
      return sess;
    })
    .catch((error) => {
      return undefined;
    });

const getCurrentAuthUser = async () => {
  try {
    const ret = Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      (session) => {
        return {
          id: session.username,
          email: session.attributes.email,
          orgId: session.attributes["custom:org_number"],
          purchase: session.attributes["custom:purchase"],
          group:
            session.signInUserSession?.accessToken?.payload["cognito:groups"] ||
            [],
        };
      }
    );
    return ret;
  } catch (e) {
    return null;
  }
};

/**
 * Get current login session
 *
 * @returns current auth session
 */
const getCurrentAuthSession = async () => {
  try {
    const ret = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return ret;
  } catch (e) {
    return null;
  }
};

/**
 * Get current auth user id and email
 *
 * @returns {id, email}
 */
const getCurrentAuthUserInfo = async () => {
  try {
    const result = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then((session) => {
      return {
        id: session.attributes.sub,
        username: session.username,
        email: session.attributes.email,
        orgId: session.attributes["custom:org_number"],
        purchase: session.attributes["custom:purchase"],
      };
    });
    return result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const setupTOTP = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    const code = await Auth.setupTOTP(user);

    return { user, code };
  } catch (e) {
    // console.error(e);
  }
};

const verifyTOTP = async (challengeAnswer) => {
  try {
    const user = await getCurrentAuthSession();

    await Auth.verifyTotpToken(user, challengeAnswer).then(async () => {
      await Auth.setPreferredMFA(user, "TOTP");
    });
    await updateUserMFA({ preferredMFA: "TOTP" });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {fn} navigateCallback
 * @param {fn} onSigninCallback
 */
const signIn = async (
  email = "",
  password = "",
  navigateCallback = () => {},
  onSigninCallback = () => {},
  confirmCallback = () => {},
  mfaCallback = () => {},
  noMfaCallback = () => {}
) => {
  try {
    if (!email || !password) {
      throw new Error();
    }
    const result = await Auth.signIn(email, password);
    if (result.challengeName === "SOFTWARE_TOKEN_MFA") {
      mfaCallback(result);
    } else if (result.preferredMFA === "NOMFA") {
      noMfaCallback(result);
    } else {
      navigateCallback();
      onSigninCallback();
    }
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      console.error(error);
      confirmCallback();
      return;
    } else if (
      error.code === "NotAuthorizedException" ||
      error.code === "UserNotFoundException"
    ) {
      throw error;
    }
    console.error("Please provide valid email and password.");
  }
};

const partnerSignIn = async (
  email = "",
  password = "",
  navigateCallback = () => {},
  onSigninCallback = () => {},
  confirmCallback = () => {},
  mfaCallback = () => {},
  noMfaCallback = () => {}
) => {
  try {
    const verify = (await verifyPartnerAccountPublic({ username: null, email }))
      ?.result;

    if (verify) {
      const user = await AuthService.getCurrentAuthUserInfo();
      if (!!user) {
        await Auth.signOut();
        window.location.reload();
      }
      await signIn(
        email,
        password,
        navigateCallback,
        onSigninCallback,
        confirmCallback,
        mfaCallback,
        noMfaCallback
      );
    } else {
      window.location.href = `${getLandingPage()}/login?email_fail=true`;
    }
  } catch (e) {
    console.error(e);
    window.location.href = `${getLandingPage()}/login?email_fail=true`;
  }
};

const signinWithGoogle = () => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `{"name": "SSO_POST_SIGNIN", "data": {"status": "OK"}}`,
  });
};

/**
 * Log out of current session
 * @param {fn} setLoggedIn
 */
const signOut = async (onSignout = () => {}) => {
  try {
    await Auth.signOut();
    onSignout();
    window.location.href = `${getLandingPage()}/login/?logout=true`;
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

/**
 * Log out of current session if user is removed
 */
const signOutOnRemoval = async () => {
  try {
    await Auth.signOut();
    window.location.href = `${getLandingPage()}/login/?removal=true`;
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

const verifyMFA = async (
  email,
  password,
  code,
  onSuccessCallback = () => {},
  onSigninCallback = () => {},
  onErrorCallback = () => {}
) => {
  try {
    const user = await Auth.signIn(email, password);
    const loggedUser = await Auth.confirmSignIn(user, code, user.challengeName);

    await pushLoginEvent();
    onSuccessCallback();
    onSigninCallback();
  } catch (e) {
    console.error(e);
    onErrorCallback();
  }
};

const forgotPassword = async (callbackFn = () => {}) => {
  try {
    let user = await getCurrentAuthUserInfo();
    let result = await Auth.forgotPassword(user?.email).then((data) => {
      callbackFn();
    });
    return result;
  } catch (e) {
    console.error(e);
  }
};

const updateUserEmail = async (email) => {
  let result;

  try {
    let user = await Auth.currentAuthenticatedUser();
    result = await Auth.updateUserAttributes(user, {
      email,
    });
  } catch (e) {
    result = {
      error: e?.message,
    };
  }

  return result;
};

const confirmUserEmailChange = async ({ code }) => {
  let result;

  try {
    result = await Auth.verifyCurrentUserAttributeSubmit("email", code);
  } catch (e) {
    result = {
      error: e?.message,
    };
  }

  return result;
};

export const AuthService = {
  checkLoginState,
  getCurrentAuthUser,
  getCurrentAuthSession,
  setupTOTP,
  verifyTOTP,
  partnerSignIn,
  signIn,
  signinWithGoogle,
  getCurrentAuthUserInfo,
  signOut,
  signOutOnRemoval,
  verifyMFA,
  forgotPassword,
  updateUserEmail,
  confirmUserEmailChange,
};
