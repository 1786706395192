import { createContext, useContext, useEffect, useState } from "react";

import { LoadingSpinner } from "components";
import { useQueryParams, useVerifyPartnerAccount } from "hooks";
import { BsPersonPlusFill } from "react-icons/bs";
import { FaStore } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { RiChatVoiceFill, RiStarSFill, RiUserFill } from "react-icons/ri";
import { Navigate } from "react-router-dom";
import { ROUTES } from "variables";

export const DashboardOptions = [
  // certification: {
  //   icon: IconCertification,
  //   title: "SMB1001 Certification",
  // },
  {
    route: "supply-chain",
    title: "Supply Chain",
    orgTitle: "Suppliers",
    orgTitleSingle: "Supplier",
    orgSingle: "supplier",
    orgPlural: "suppliers",
    check: "isTypeSupplyChain",
    access: "supplyChainCustomers",
    icon: <HiOfficeBuilding />,
  },
  {
    route: "membership",
    title: "Membership",
    orgTitle: "Members",
    orgTitleSingle: "Member",
    orgSingle: "member",
    orgPlural: "members",
    check: "isTypeMembership",
    access: "membershipCustomers",
    icon: <RiStarSFill />,
  },
  {
    route: "msp",
    title: "MSPs",
    orgTitle: "Clients",
    orgTitleSingle: "Client",
    orgSingle: "client",
    orgPlural: "clients",
    check: "isTypeMSP",
    access: "MSPCustomers",
    icon: <RiUserFill />,
  },
  {
    route: "consultant",
    title: "Consultant",
    orgTitle: "Clients",
    orgTitleSingle: "Client",
    orgSingle: "client",
    orgPlural: "clients",
    icon: <RiChatVoiceFill />,
    check: "isTypeConsultant",
    access: "consultantCustomers",
  },
  {
    route: "vendor",
    title: "Vendor",
    orgTitle: "Partners",
    orgTitleSingle: "Partner",
    orgSingle: "partner",
    orgPlural: "partners",
    icon: <RiChatVoiceFill />,
    check: "isTypeVendor",
    access: "vendorCustomers",
  },
  {
    route: "franchise",
    title: "Franchise",
    orgTitle: "Franchises",
    orgTitleSingle: "Franchise",
    orgSingle: "franchise",
    orgPlural: "franchises",
    icon: <FaStore />,
    check: "isTypeFranchise",
    access: "franchiseCustomers",
  },
  {
    route: "referrer",
    title: "Referrer",
    orgTitle: "Referrals",
    orgTitleSingle: "Referral",
    orgSingle: "referral",
    orgPlural: "referrals",
    check: "isTypeReferrer",
    access: "referrerCustomers",
    icon: <BsPersonPlusFill />,
  },
];

const DashboardContext = createContext(null);

export function DashboardProvider({ children }) {
  const [dashboard, setDashboard] = useState(() => {
    return DashboardOptions.find(
      (option) => option.route === sessionStorage.getItem("dashboard")
    );
  });

  useEffect(() => {
    if (!!dashboard) {
      console.log(`Dashboard set ${dashboard?.route}`);
      sessionStorage.setItem("dashboard", dashboard?.route);
    } else {
      console.log("No Dashboard Selected");
    }
  }, [dashboard]);

  return (
    <DashboardContext.Provider value={{ dashboard, setDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
}

export function RequireDashboard({ children }) {
  const { dashboard, setDashboard } = useDashboard();
  const { dashboard: dashboardFromParams } = useQueryParams();

  useEffect(() => {
    if (dashboardFromParams) {
      const dashboardOption = DashboardOptions.find(
        (option) => option.route === dashboardFromParams
      );
      setDashboard(dashboardOption);

      // Remove dashboard from URL query params
      const url = new URL(window.location.href);
      url.searchParams.delete("dashboard");
      window.history.replaceState({}, "", url);
      window.location.reload();
    }
  }, [dashboardFromParams]);

  return !!dashboard || !!dashboardFromParams ? (
    children
  ) : (
    <Navigate to={ROUTES.ROOT} />
  );
}

export function RequestDashboard({ children }) {
  const verify = useVerifyPartnerAccount();
  const { dashboard, setDashboard } = useDashboard();

  useEffect(() => {
    if (!dashboard && verify.data) {
      setDashboard(
        DashboardOptions.find((option) => verify.data.partner[option.check])
      );
    }
  }, [dashboard, verify]);

  return !!dashboard ? children : <LoadingSpinner />;
}

export function useDashboard() {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a Dashboard Provider");
  }
  return context;
}
