import React, { useRef, useState } from "react";

import { RemovePartnerOrgsData } from "api";
import {
  Checkbox,
  FlowbitePagination,
  ReviewOrganisationsEditModal,
  ReviewOrganisationsRemoveModal,
} from "components";
import { useDashboard } from "contexts";
import { Table, Tooltip } from "flowbite-react";
import { useTableScrollPosition } from "hooks";
import {
  HiCheck,
  HiOutlineBan,
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";

import TableTopSummary from "./table-top-summary";
import Flag from "react-world-flags";
import { ALLOWED_COUNTRIES } from "variables";

export default function ReviewOrganisationsTable({
  validOrgIds,
  invalidOrgIds,
  totalOrgIds,
  partnerOrgsStatus,
  updatePartnerOrgsStatus = () => {},
  startPolling = () => {},
  setLoading = () => {},
}) {
  const { dashboard } = useDashboard();

  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isMultipleSelected, setIsMultipleSelected] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editOrgs, setEditOrgs] = useState([]);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(
    partnerOrgsStatus?.progress?.length / pageOption
  );

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setPage(
      Math.min(page, Math.ceil(partnerOrgsStatus?.progress?.length / value))
    );
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSelectAllChange = (isChecked) => {
    setSelectedOrgs(isChecked ? partnerOrgsStatus?.progress : []);
    setIsAllSelected(isChecked);
    setIsMultipleSelected(isChecked);
  };

  const handleRowSelectChange = (isChecked, org) => {
    const updatedSelectedRows = isChecked
      ? [...selectedOrgs, org]
      : selectedOrgs.filter((row) => row !== org);
    setSelectedOrgs(updatedSelectedRows);
    setIsAllSelected(
      updatedSelectedRows.length === partnerOrgsStatus?.progress?.length
    );
    setIsMultipleSelected(updatedSelectedRows.length > 0);
  };

  const handleEditOrgs = (orgs = []) => {
    setIsEditModalOpen(true);
    setEditOrgs(orgs);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditOrgs([]);
  };

  const handleRemoveOrgs = async (orgs = []) => {
    setLoading(true);

    await RemovePartnerOrgsData({
      dashboard: dashboard?.route,
      data: orgs,
    });
    updatePartnerOrgsStatus();
  };

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(false);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
  };

  const getOrgRenders = () => {
    const startIndex = (page - 1) * pageOption;
    const currentOrgs = partnerOrgsStatus?.progress?.slice(
      startIndex,
      startIndex + pageOption
    );

    return currentOrgs?.map((org, index) => (
      <OrgTableRow
        key={index}
        org={org}
        scrollPos={tableScroll.scrollLeft}
        isSelected={selectedOrgs.includes(org)}
        onSelectChange={handleRowSelectChange}
        handleEditOrgs={handleEditOrgs}
        handleRemoveOrgs={handleRemoveOrgs}
      />
    ));
  };

  const getOrgRendersMobile = () => {
    const startIndex = (page - 1) * pageOption;
    const currentOrgs = partnerOrgsStatus?.progress?.slice(
      startIndex,
      startIndex + pageOption
    );

    return currentOrgs?.map((org, index) => (
      <OrgTableRowMobile
        key={index}
        org={org}
        orgs={currentOrgs}
        isSelected={selectedOrgs.includes(org)}
        onSelectChange={handleRowSelectChange}
        selectAllChecked={isAllSelected}
        handleEditOrgs={handleEditOrgs}
        handleRemoveOrgs={handleRemoveOrgs}
      />
    ));
  };

  console.log("partnerOrgsStatus", partnerOrgsStatus);

  return (
    <>
      <section className="border-primary">
        <TableTopSummary
          validOrgIds={validOrgIds}
          invalidOrgIds={invalidOrgIds}
          totalOrgIds={totalOrgIds}
          isAllSelected={isAllSelected}
          isMultipleSelected={isMultipleSelected}
          handleEditOrgs={() => handleEditOrgs(selectedOrgs)}
          handleRemoveOrgs={() => handleRemoveOrgs(selectedOrgs)}
        />

        {/****************************** PC TABLE ******************************/}
        <div className="hidden laptop:block">
          <Table ref={tableRef} hoverable>
            <Table.Head>
              <Table.HeadCell className="w-16">
                {/* <Tooltip
                    content={`Select All ${dashboard?.orgTitle}`}
                    placement="top"
                  > */}
                <Checkbox
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
                {/* </Tooltip> */}
              </Table.HeadCell>
              <Table.HeadCell
                className={`sticky left-16 w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
              >
                Preferred Entity Name
              </Table.HeadCell>
              <Table.HeadCell className="w-48">Country</Table.HeadCell>
              <Table.HeadCell className="w-48">Organisation ID</Table.HeadCell>
              <Table.HeadCell className="w-48">Entity Name</Table.HeadCell>
              <Table.HeadCell className="w-36">Status</Table.HeadCell>
              <Table.HeadCell className="w-28">Manage</Table.HeadCell>
            </Table.Head>

            <Table.Body>{getOrgRenders()}</Table.Body>
          </Table>
        </div>

        {/****************************** MOBILE TABLE ******************************/}
        <div className="laptop:hidden">
          <div className="surface-secondary text-primary flex flex-row items-center justify-between border p-4 font-medium">
            <div className="flex flex-row items-center gap-4">
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAllChange}
              />
              Select All
            </div>

            {isMultipleSelected && (
              <div className="flex flex-row items-center gap-1">
                <button
                  className="text-gray flex flex-row items-center gap-1 rounded-lg px-2 py-1
                          hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
                  onClick={() => handleEditOrgs(selectedOrgs)}
                >
                  <HiOutlinePencil className="size-4" />
                  <span className="text-sm font-medium">
                    Edit {isAllSelected ? "All" : "Selected"}
                  </span>
                </button>
                <button
                  className="text-gray flex flex-row items-center gap-1 rounded-lg px-2 py-1
                          hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
                  onClick={() => handleRemoveOrgs(selectedOrgs)}
                >
                  <HiOutlineTrash className="size-4" />
                  <span className="text-sm font-medium">
                    Remove {isAllSelected ? "All" : "Selected"}
                  </span>
                </button>
              </div>
            )}
          </div>

          <div className="flex flex-col border-x">{getOrgRendersMobile()}</div>
        </div>

        <FlowbitePagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={partnerOrgsStatus?.progress?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      </section>

      <ReviewOrganisationsEditModal
        isOpen={isEditModalOpen}
        allOrgs={partnerOrgsStatus?.progress}
        editOrgs={editOrgs}
        updatePartnerOrgsStatus={updatePartnerOrgsStatus}
        startPolling={startPolling}
        setLoading={setLoading}
        handleCloseModal={handleCloseEditModal}
      />

      <ReviewOrganisationsRemoveModal
        isOpen={isRemoveModalOpen}
        setLoading={setLoading}
        handleCloseModal={handleCloseRemoveModal}
      />
    </>
  );
}

function OrgTableRow({
  org,
  scrollPos,
  isSelected,
  onSelectChange,
  handleEditOrgs = () => {},
  handleRemoveOrgs = () => {},
}) {
  return (
    <Table.Row>
      <Table.Cell>
        {/* <Tooltip
            content={`Select ${dashboard?.orgTitleSingle}`}
            placement="bottom"
          > */}
        <Checkbox
          checked={isSelected}
          onChange={(isChecked) => onSelectChange(isChecked, org)}
        />
        {/* </Tooltip> */}
      </Table.Cell>
      <Table.Cell
        className={`sticky z-5 left-16${scrollPos > 0 ? " shadow-table" : ""}`}
      >
        {org.addedAs
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </Table.Cell>
      <Table.Cell>
        <span className="flex flex-row items-center gap-1">
          <Flag
            code={org?.country}
            className="h-3.5 w-5"
            // fallback={<span>🌏</span>}
          />
          {
            Object.entries(ALLOWED_COUNTRIES).find(
              ([code, country]) => country?.countryCode === org?.country
            )?.[0]
          }
        </span>
      </Table.Cell>
      <Table.Cell>{org.orgId?.split(" ")[1]}</Table.Cell>
      <Table.Cell>{org.name}</Table.Cell>
      <Table.Cell>
        {
          {
            INVALID_ORG: (
              <div className="flex flex-row items-center gap-2">
                <HiOutlineBan className="size-4 text-red-600" />
                Invalid
              </div>
            ),
            PENDING: (
              <div className="flex flex-row items-center gap-2">
                <HiCheck className="size-4 text-green-600" />
                Valid
              </div>
            ),
          }?.[org.orgId?.split(" ")[0]]
        }
      </Table.Cell>

      <Table.Cell>
        <div className="flex flex-row items-center gap-2 border-0">
          <Tooltip content="Edit" placement="bottom">
            <button
              className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:bg-gray-300"
              onClick={() => handleEditOrgs([org])}
            >
              <HiOutlinePencil className="size-4" />
            </button>
          </Tooltip>

          <Tooltip content="Remove" placement="bottom">
            <button
              className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:bg-gray-300"
              onClick={() => handleRemoveOrgs([org])}
            >
              <HiOutlineTrash className="size-4" />
            </button>
          </Tooltip>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

function OrgTableRowMobile({
  index,
  org,
  isSelected,
  onSelectChange,
  handleEditOrgs = () => {},
  handleRemoveOrgs = () => {},
}) {
  const { dashboard } = useDashboard();

  return (
    <div
      key={index}
      className="hover:surface-db-stepper flex flex-col gap-4 border-b p-4"
    >
      <div className="flex flex-row items-center gap-4 font-medium">
        <Tooltip
          content={`Select ${dashboard?.orgTitleSingle}`}
          placement="bottom"
        >
          <Checkbox
            checked={isSelected}
            onChange={(isChecked) => onSelectChange(isChecked, org)}
          />
        </Tooltip>
        Select
      </div>

      <div className="grid grid-cols-[auto_1fr] gap-4">
        <span className="text-gray">Preferred Name:</span>
        <span>{org?.addedAs}</span>
        <span className="text-gray">Entity Name:</span>
        <span>{org?.name}</span>
        <span className="text-gray">Organisation ID:</span>
        <span>{org?.orgId?.split(" ")?.[1]}</span>
        <span className="text-gray">Status:</span>

        {
          {
            INVALID_ORG: (
              <div className="flex flex-row items-center gap-2">
                <HiOutlineBan className="size-4 text-red-600" />
                Invalid
              </div>
            ),
            PENDING: (
              <div className="flex flex-row items-center gap-2">
                <HiCheck className="size-4 text-green-600" />
                Valid
              </div>
            ),
          }?.[org.orgId?.split(" ")[0]]
        }
      </div>

      <div className="flex flex-row items-center gap-2 font-medium">
        <Tooltip content="Edit" placement="bottom">
          <button
            className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
            onClick={() => handleEditOrgs([org])}
          >
            <HiOutlinePencil className="size-4" />
          </button>
        </Tooltip>

        <Tooltip content="Remove" placement="bottom">
          <button
            className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
            onClick={() => handleRemoveOrgs([org])}
          >
            <HiOutlineTrash className="size-4" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
