import { useEffect, useState } from "react";

import { updatePartnerDetails } from "api";
import { Button, OverlaySpinner, TextInput } from "components";
import { Modal } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { capitalize } from "services";

const DEFAULT = {
  govIdName: "",
  orgId: "",
  name: "",
  industry: "",
  preferredName: "",
  website: "",
};

export function UpdateOrganisationModal({
  isOpen,
  org,
  handleCloseModal = () => {},
  handleSync = async () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [updateOrg, setUpdateOrg] = useState({});

  const handleInput = (event) => {
    setUpdateOrg((prevUpdateOrg) => ({
      ...prevUpdateOrg,
      [event?.target?.name]: event?.target?.value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    await updatePartnerDetails({
      name: updateOrg?.preferredName,
      website: updateOrg?.website,
    });
    await handleSync();

    setIsLoading(false);
    handleCloseModal(true);
  };

  useEffect(() => {
    setUpdateOrg({ ...DEFAULT, ...org });
  }, [isOpen]);

  return (
    <Modal
      show={isOpen}
      size="2xl"
      position="center"
      onClose={handleCloseModal}
    >
      <Modal.Header>Update Your Organisation</Modal.Header>
      <Modal.Body>
        {!!isLoading && <OverlaySpinner />}
        <div className="flex flex-grow flex-col gap-4 self-stretch px-1">
          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">{updateOrg?.govIdName}</span>
            <TextInput
              className="w-full"
              color="gray"
              disabled
              required
              type="tel"
              value={updateOrg?.orgId}
            />
          </div>

          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">Legal Entity Name</span>
            <TextInput
              className="w-full"
              color="gray"
              disabled
              required
              type="text"
              value={updateOrg?.name}
            />
          </div>

          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">Entity Type</span>
            <TextInput
              className="w-full"
              color="gray"
              disabled
              required
              type="text"
              value={capitalize(updateOrg?.industry)}
            />
          </div>

          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">Preferred Entity Name</span>
            <TextInput
              className="w-full"
              color="gray"
              name="preferredName"
              placeholder="Enter preferred name"
              type="text"
              value={updateOrg?.preferredName}
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-grow flex-col gap-2 self-stretch">
            <span className="text-gray-600">Website URL</span>
            <TextInput
              className="w-full"
              color="gray"
              name="website"
              placeholder="Enter website URL"
              type="url"
              value={updateOrg?.website}
              onInput={handleInput}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          customClasses="w-fit"
          label="Submit"
          leftIcon={<HiCheck className="size-4" />}
          variant="blue"
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}
