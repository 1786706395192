import { useEffect, useState } from "react";

import { LoadingSpinner } from "components";
import { useDashboard } from "contexts";
import { usePartnerOrgsStatus, useVerifyPartnerAccount } from "hooks";
import { Navbar } from "layouts";
import { SidebarProcess } from "layouts/sidebar/sidebar-process";
import { scrollToTop } from "utils";
import { CheckOrganisations } from "./check/check-organisations";
import { FinishAddOrganisations } from "./finish/finish-organisations";
import { ImportOrganisations } from "./import/import-organisations";
import { ReviewOrganisations } from "./review/review-organisations";
import { ALLOWED_COUNTRIES } from "variables";

export function AddOrganisations() {
  const partnerState = useVerifyPartnerAccount();
  const { dashboard } = useDashboard();
  const {
    partnerOrgsStatus,
    isSuccess,
    updatePartnerOrgsStatus,
    startPolling,
    stopPolling,
  } = usePartnerOrgsStatus(dashboard?.route);
  const [defaultCountry, setDefaultCountry] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [isJustFinished, setIsJustFinished] = useState(
    sessionStorage.getItem(`${dashboard?.route}-just-finished`) === "true" ||
      false
  );

  const stepTitles = [
    `Add ${dashboard?.orgTitle}`,
    "Checking Organisation IDs",
    "Review",
    "Finish",
  ];

  useEffect(() => {
    setDefaultCountry(
      Object.entries(ALLOWED_COUNTRIES).find(
        ([key, value]) =>
          value?.countryCode === partnerState?.data?.organisation?.country
      )?.[0]
    );
  }, [partnerState?.data]);

  const getSteps = () => {
    return stepTitles.map((_, index) => {
      if (index < currentStep || currentStep >= stepTitles.length - 1) {
        return "completed";
      } else if (index === currentStep) {
        return "inprogress";
      } else {
        return "todo";
      }
    });
  };

  async function syncData() {
    await updatePartnerOrgsStatus();
  }

  const setup = () => {
    if (!!isJustFinished) {
      setCurrentStep(3);
      return;
    }

    if (!partnerOrgsStatus?.partner?.addOrgStep) {
      setCurrentStep(0);
      setIsLoading(false);
      return;
    }

    switch (partnerOrgsStatus?.partner?.addOrgStep) {
      case "0":
      case "add":
        setCurrentStep(0);
        break;
      case "orgId":
        setCurrentStep(1);
        break;
      case "review":
        setCurrentStep(2);
        break;
      default:
        console.error("Invalid step in add orgs process!");
    }
  };

  const handleFinish = () => {
    sessionStorage.setItem(`${dashboard?.route}-just-finished`, "false");
    setIsJustFinished(false);
  };

  useEffect(() => {
    if (!!isSuccess) {
      setup();
      setIsLoading(false);
      setTimeout(() => stopPolling(), 5000);
    }
  }, [partnerOrgsStatus]);

  useEffect(() => scrollToTop, [currentStep]);

  return (
    <>
      {!!defaultCountry ? (
        <>
          <Navbar
            variant="primeProcess"
            processTitle={`Add your ${dashboard?.orgTitle.toLowerCase()}`}
          />

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="surface-primary relative grid flex-grow grid-rows-[auto_1fr] laptop:grid-cols-[auto_1fr] laptop:grid-rows-1">
              <SidebarProcess
                title="Steps"
                steps={getSteps()}
                stepTitles={stepTitles}
              />

              <div className="grid grid-cols-1 grid-rows-[1fr_auto]">
                {
                  [
                    <ImportOrganisations
                      defaultCountry={defaultCountry}
                      setIsLoading={setIsLoading}
                      startPolling={startPolling}
                    />,
                    <CheckOrganisations
                      setLoading={setIsLoading}
                      partnerOrgsStatus={partnerOrgsStatus}
                      updatePartnerOrgsStatus={updatePartnerOrgsStatus}
                      startPolling={startPolling}
                      stopPolling={stopPolling}
                    />,
                    <ReviewOrganisations
                      partnerOrgsStatus={partnerOrgsStatus}
                      updatePartnerOrgsStatus={updatePartnerOrgsStatus}
                      startPolling={startPolling}
                      stopPolling={stopPolling}
                      setLoading={setIsLoading}
                      setIsJustFinished={setIsJustFinished}
                    />,
                    <FinishAddOrganisations handleFinish={handleFinish} />,
                  ][currentStep]
                }
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
