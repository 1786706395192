import {
  useEffect,
  useState,
} from 'react';

import {
  Breadcrumbs,
  GapAssessmentsTable,
  LoadingSpinner,
} from 'components';
import { useDashboard } from 'contexts';
import {
  Alert,
  Dropdown,
} from 'flowbite-react';
import {
  useFetchGAPAssessments,
  useFetchSuppliersWidgetData,
  useSyncFetchSuppliersWidgetData,
  useVerifyPartnerAccount,
} from 'hooks';
import { PageHeader } from 'layouts';
import {
  HiChartPie,
  HiCheckCircle,
  HiExclamationCircle,
} from 'react-icons/hi';
import { getGAPQuestionnaireCount } from 'services';
import {
  INITIAL_GAP_ASSESSMENTS,
  ROUTES,
} from 'variables';

const STANDARDS = ["SMB1001:2025", "SMB1001:2023"];

export function GapAssessmentsPage() {
  const { dashboard } = useDashboard();

  const [selectedStandard, setSelectedStandard] = useState("SMB1001:2025");

  const [isLoading, setIsLoading] = useState(true);
  const [gapAssessments, setGapAssessments] = useState();
  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const gapAssessmentsState = useFetchGAPAssessments();
  const verifyPartnerState = useVerifyPartnerAccount();
  const fetchSuppliersState = useFetchSuppliersWidgetData();
  const syncFetchSuppliers = useSyncFetchSuppliersWidgetData();

  const setup = async () => {
    if (!!gapAssessmentsState?.data && !!verifyPartnerState?.data) {
      filterAssessmentsStandard(selectedStandard);
    }
  };

  const syncSuppliersData = async () => {
    await syncFetchSuppliers({ dashboard: dashboard?.route });
  };

  useEffect(() => {
    if (!!dashboard) {
      syncSuppliersData();
    }
  }, [dashboard]);

  useEffect(() => {
    setup();
    setIsLoading(false);
  }, [
    gapAssessmentsState?.data,
    verifyPartnerState?.data,
    fetchSuppliersState?.data,
  ]);

  useEffect(() => {
    if (!!gapAssessments) console.log("GAP ASSESSMENTS", gapAssessments);
  }, [gapAssessments]);

  function filterAssessmentsStandard(standard) {
    const standardYear = standard.split(":")[1];
    let assessments = (gapAssessmentsState?.data?.assessments || []).filter(
      (a) => a?.dashboard === dashboard?.route,
    );
    assessments = assessments.filter((a) => a?.standard === standardYear);
    if (assessments?.length > 0 && !!verifyPartnerState?.data) {
      const existingLevels = [
        ...new Set(assessments.map((assessment) => assessment.maximumLevel)),
      ];
      const fullLevelsSet = new Set([1, 2, 3, 4, 5]);
      const missingLevels = Array.from(fullLevelsSet).filter(
        (level) => !existingLevels.includes(level),
      );

      setGapAssessments([
        ...assessments?.map((a) => ({
          id: a?.id,
          level:
            INITIAL_GAP_ASSESSMENTS[
              INITIAL_GAP_ASSESSMENTS.length - a?.maximumLevel
            ].level,
          standard: a?.standard?.startsWith("SMB1001:")
            ? a.standard
            : `SMB1001:${a?.standard || "2023"}`,
          submissions: fetchSuppliersState?.data?.partner?.customers?.reduce(
            (result, customer) => {
              customer?.assessmentResults?.forEach((assessment) => {
                const token = assessment?.assessmentToken;
                if (!!token) {
                  if (!!result[token]) {
                    result[token]++;
                  } else {
                    result[token] = 1;
                  }
                }
              });
              return result;
            },
            {},
          ),
          dashboardTypeIcon: "",
          dashboardRoute: a?.dashboard,
          token: a?.token,
          questions: getGAPQuestionnaireCount(a),
          status: !!a?.token ? "complete" : "notstarted",
          levelId: a?.maximumLevel,
        })),
        ...(
          INITIAL_GAP_ASSESSMENTS.filter((assessment) =>
            missingLevels.includes(assessment.levelId),
          ) || []
        )?.map((assessment) => ({
          ...assessment,
          dashboardTypeIcon: dashboard?.title,
          dashboardRoute: dashboard?.route,
          standard,
        })),
      ]);
    } else {
      setGapAssessments(
        INITIAL_GAP_ASSESSMENTS.map((item) => {
          let dashboardRoute = dashboard?.route;
          return {
            ...item,
            dashboardTypeIcon: "",
            dashboardRoute,
            standard,
          };
        }),
      );
    }
    setSelectedStandard(standard);
  }

  return (
    <>
      {isLoading || gapAssessmentsState?.isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          {!!infoMsg && (
            <Alert
              color={"green"}
              onDismiss={() => setInfoMsg("")}
              icon={HiCheckCircle}
            >
              <span className="text-base font-semibold">{infoMsg}</span>
            </Alert>
          )}

          {!!errMsg && (
            <Alert
              color={"red"}
              onDismiss={() => setErrMsg("")}
              icon={HiExclamationCircle}
            >
              <span className="text-base font-semibold">{errMsg}</span>
            </Alert>
          )}

          <PageHeader
            variant="primePage"
            pageName={`SMB1001 GAP Assessments`}
            // btnLabel={`Add assessments`}
            // onclick={() => navigate(ROUTE.ROOT)}
            customBreadcrumbs={
              <Breadcrumbs
                icon={HiChartPie}
                items={{
                  "": ROUTES.ROOT,
                  Assessments: ROUTES.ASSESSMENTS,
                  "SMB1001 GAP Assessments": ROUTES.GAP,
                }}
              />
            }
          />

          <div className="border-primary !text-primary flex w-fit rounded-lg border p-4 !font-semibold">
            <Dropdown label={selectedStandard} inline>
              {STANDARDS.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => filterAssessmentsStandard(item)}
                  className="text-gray font-normal"
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>

          <GapAssessmentsTable
            data={gapAssessments?.sort((a, b) => b?.levelId - a?.levelId)}
            setInfoMsg={setInfoMsg}
            setErrMsg={setErrMsg}
          />
        </>
      )}
    </>
  );
}
