import { useState } from "react";

import { UploadPartnerOrgsData } from "api";
import { Button } from "components";
import { useDashboard } from "contexts";
import { Alert, Modal, Tooltip } from "flowbite-react";
import { Footer } from "layouts";
import { BiImport, BiSolidErrorAlt } from "react-icons/bi";
import {
  HiExclamation,
  HiInformationCircle,
  HiPlus,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";

import { ImportOrgsManual } from "./import-organisations-manual";
import { ImportOrgsUpload } from "./import-organisations-upload";
import { ALLOWED_COUNTRIES } from "variables";
import { capitalize } from "services";

export function ImportOrganisations({
  defaultCountry,
  setIsLoading = () => {},
  startPolling = () => {},
}) {
  const { dashboard } = useDashboard();

  const [orgs, setOrgs] = useState([
    {
      country: defaultCountry,
      name: "",
      orgId: "",
      govIdName: ALLOWED_COUNTRIES[defaultCountry]?.govIdName,
      email: "",
      mobile: "",
      error: {
        country: false,
        name: false,
        orgId: false,
        email: false,
        mobile: false,
      },
      duplicate: false,
    },
  ]);
  const [importOption, setImportOption] = useState(0);
  const [infoMsg, setInfoMsg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [warnMsg, setWarnMsg] = useState(null);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const importOptions = [
    {
      description: `import a list of ${dashboard?.orgPlural} (csv file), or `,
      label: "Import a File",
      icon: <BiImport />,
    },
    {
      description: `add ${dashboard?.orgPlural} manually one at a time`,
      label: "Add Manually",
      icon: <HiPlus />,
    },
  ];

  // const handleNextStepInternal = async () => {
  //   if (importOption === "json") {
  //     // Handle manual

  //     // Check that there is an org
  //     if (!orgs || orgs.length === 0) {
  //       setErr({
  //         type: "empty",
  //         msg: `You must add at least one ${dashboard?.orgTitle
  //           .slice(0, -1)
  //           .toLowerCase()} to continue`,
  //       });
  //       return;
  //     }

  //     // Check each field of each org is valid
  //     let isInvalidField = false;
  //     orgs.forEach((org) => {
  //       // Trim all fields before processing
  //       org.name = org?.name?.trim();
  //       org.orgId = org?.orgId?.trim();
  //       org.email = org?.email?.trim();
  //       org.mobile = org?.mobile?.trim();

  //       // Validate name field
  //       if (!validateEmpty(org.name)) {
  //         org.error.name = true;
  //         isInvalidField = true;
  //       }

  //       // Validate orgId field
  //       if (!validateEmpty(org?.orgId) || !validateOrgId(org?.orgId)) {
  //         org.error.orgId = true;
  //         isInvalidField = true;
  //       }

  //       // Validate email field
  //       if (!validateEmpty(org.email || !validateEmail(org.email))) {
  //         org.error.email = true;
  //         isInvalidField = true;
  //       }

  //       // Validate mobile field
  //       if (!validateEmpty(org.mobile) || !validateMobile(org.mobile)) {
  //         org.error.mobile = true;
  //         isInvalidField = true;
  //       }
  //     });

  //     // Checking for duplicate ABNs

  //     if (orgs.some((org) => !!org.duplicate)) {
  //       setShowWarn({
  //         type: "duplicates",
  //         msg: "You have entered duplicate ABNs",
  //       });
  //       return;
  //     }

  //     if (isInvalidField) {
  //       setOrgs(orgs);
  //       setErr({
  //         type: "invalid",
  //         msg: "Some fields are invalid. Please try again.",
  //       });
  //       return;
  //     }

  //     // Convert orgs array to JSON
  //     setUploadData(
  //       JSON.stringify(
  //         orgs.map((org) => ({
  //           ...org,
  //         })),
  //       ),
  //     );
  //   } else {
  //     // If importMethod is csv, dataToSend is the selectedFile
  //     if (selectedFile) {
  //       const reader = new FileReader();

  //       reader.onload = async (e) => {
  //         // Once file reading is complete, prepare and log the object
  //         const base64Data = e.target.result;
  //         setUploadData({
  //           name: selectedFile.name,
  //           size: selectedFile.size,
  //           type: selectedFile.type,
  //           data: base64Data,
  //         });
  //       };

  //       reader.onerror = (error) => {
  //         console.error("Error reading file:", error);
  //       };

  //       reader.readAsDataURL(selectedFile);
  //     }
  //   }
  // };

  const handleUploadOrgs = async (uploadData) => {
    setIsLoading(true);
    try {
      await UploadPartnerOrgsData({
        dashboard: dashboard?.route,
        data: JSON.stringify(uploadData),
        type: "json",
      });
      setInfoMsg("Upload Successful! Processing will begin momentarily...");
      startPolling();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return;
    }
  };

  const handleChangeImportOption = (index) => {
    setImportOption(index);
    setInfoMsg(null);
    setErrMsg(null);
    setWarnMsg(null);
  };

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  const handleImportOrgsComplete = (data) => {
    if (!data?.length) {
      return;
    }
    let orgsData = [];
    data.forEach((orgData) => {
      orgsData = [
        ...orgsData,
        {
          name: orgData.name,
          orgId: orgData.organisationid,
          email: orgData.email,
          mobile: orgData.mobile,
          country: capitalize(orgData.country),
          error: {
            country: false,
            name: false,
            orgId: false,
            email: false,
            mobile: false,
          },
          duplicate: orgsData.some((org) => org.orgId === orgData.orgId),
        },
      ];
    });
    orgsData = [...orgs, ...orgsData];
    orgsData = orgsData.filter((orgData) =>
      ["name", "orgId", "email", "mobile"].some((prop) => !!orgData[prop])
    );
    setOrgs(orgsData);
    handleChangeImportOption(1);
  };

  return (
    <>
      <div className="flex flex-col gap-8 p-8">
        <div className="flex flex-col gap-5 self-stretch">
          <div className="text-secondary flex items-center gap-2 text-lg font-bold">
            Add {dashboard?.orgTitle}
            <div className="flex items-center gap-1">
              {/* <Tooltip
                  animation="duration-150"
                  content="Video Tutorial"
                  placement="bottom"
                  trigger="hover"
                >
                  <HiMiniPlayCircle
                    className="btn-text-green size-5 cursor-pointer"
                    onClick={handleVideo}
                  />
                </Tooltip> */}
              <Tooltip
                animation="duration-150"
                content={
                  <div className="flex flex-col gap-2">
                    <span>Add {dashboard?.orgTitle}</span>
                    <span className="font-normal text-gray-300">
                      Use this tool to add entries for your SMB{" "}
                      {dashboard?.orgTitle} before they are certified.
                      <br />
                      <br />
                      {dashboard?.orgTitle} will appear in the clients list upon
                      successful ABN validation and can then login and complete
                      GAP assessments.
                    </span>
                  </div>
                }
                placement="bottom"
                trigger="click"
              >
                <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
              </Tooltip>
            </div>
          </div>

          {!!infoMsg && (
            <Alert
              color="green"
              onDismiss={() => setInfoMsg(null)}
              icon={HiInformationCircle}
            >
              <span className="text-base font-semibold">{infoMsg}</span>
            </Alert>
          )}

          {!!errMsg && (
            <Alert
              color="red"
              onDismiss={() => setErrMsg(null)}
              icon={HiExclamation}
            >
              <span className="text-base font-semibold">{errMsg}</span>
            </Alert>
          )}

          {!!warnMsg && (
            <Alert
              color="yellow"
              onDismiss={() => setWarnMsg(null)}
              icon={BiSolidErrorAlt}
            >
              <span className="text-base font-semibold">{warnMsg}</span>
            </Alert>
          )}

          <span className="text-primary text-sm font-normal">
            To create your {dashboard?.orgTitleSingle} register, enter them
            below:
          </span>

          {/* <NumberList
              items={importOptions?.map((option) => option?.description)}
            /> */}
        </div>

        <div className="flex flex-col gap-3 self-stretch">
          <span className="text-secondary text-base font-semibold">
            Enter {dashboard?.orgTitle}
          </span>

          <span className="text-secondary text-base font-semibold">
            Select Option
          </span>

          <div className="flex flex-row gap-3">
            {importOptions?.map((option, index) => (
              <Button
                disabled={importOption === index}
                label={option?.label}
                leftIcon={option?.icon}
                variant={importOption === index ? "white" : "blue"}
                onClick={() => handleChangeImportOption(index)}
              />
            ))}
          </div>
        </div>

        {
          [
            <ImportOrgsUpload
              setInfoMsg={setInfoMsg}
              setErrMsg={setErrMsg}
              setWarnMsg={setWarnMsg}
              handleImportComplete={handleImportOrgsComplete}
            />,
            <ImportOrgsManual
              orgs={orgs}
              setOrgs={setOrgs}
              setIsLoading={setIsLoading}
              setErrMsg={setErrMsg}
              setWarnMsg={setWarnMsg}
              handleUploadOrgs={handleUploadOrgs}
            />,
          ][importOption]
        }
      </div>

      <Footer />

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">Add {dashboard?.orgTitle}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/widget_1.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>
    </>
  );
}
