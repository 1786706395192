import { Modal } from "flowbite-react";

export function ReviewOrganisationsRemoveModal({
  isOpen,
  handleCloseModal = () => { },
}) {
  return (
    <>
      <Modal
        show={isOpen}
        size="md"
        position="center"
        onClose={handleCloseModal}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          {/* <div className="flex flex-col gap-2 self-stretch">
            {org?.orgId?.split(" ")?.[1] === editModalOrg.orgId &&
              org?.orgId?.split(" ")?.[0] === "INVALID_ORG" && (
                <span className="body-sm font-medium text-red-600">
                  This orgId has already been submitted and flagged as INVALID and
                  needs to be edited or removed.
                </span>
              )}

            <div className="hidden items-center gap-3 self-stretch tablet:flex">
              <span className="text-primary w-full text-sm font-medium">
                {dashboard?.orgTitleSingle}
              </span>
              <span className="text-primary w-full text-sm font-medium">
                Email
              </span>
              <span className="text-primary w-full text-sm font-medium">
                Mobile
              </span>
            </div>

            <div className="flex flex-col items-center gap-3 self-stretch tablet:flex-row">
              <span className="text-primary flex w-full text-sm font-medium tablet:hidden">
                {dashboard?.orgSingle}
              </span>

              <TextInput
                color={!!isEditModalErr["name"] ? "failure" : "gray"}
                name="name"
                placeholder={`Enter ${dashboard?.orgTitleSingle} name`}
                required
                type="text"
                value={editModalOrg.name}
                className={"w-full"}
                onChange={handleModalInput}
              />

              <TextInput
                color={
                  !!isEditModalErr["orgId"] ||
                  org?.orgId?.split(" ")?.[1] === editModalOrg.orgId
                    ? "failure"
                    : !!isEditModalWarn
                      ? "warning"
                      : "gray"
                }
                name="orgId"
                placeholder={`Enter ${dashboard?.orgTitleSingle} orgId`}
                required
                type="tel"
                value={editModalOrg.orgId}
                className={"w-full"}
                onChange={handleModalInput}
                onKeyPress={filterNumberAndSpaceKeyPress}
                onPaste={filterNumberPaste}
              />

              <TextInput
                color={!!isEditModalErr["email"] ? "failure" : "gray"}
                name="email"
                placeholder={`Enter ${dashboard?.orgTitleSingle} email`}
                required
                type="email"
                className={"w-full"}
                value={editModalOrg.email}
                onChange={handleModalInput}
              />

              <TextInput
                color={!!isEditModalErr["mobile"] ? "failure" : "gray"}
                name="mobile"
                placeholder={`Enter ${dashboard?.orgTitleSingle} mobile`}
                required
                type="tel"
                className={"w-full"}
                value={editModalOrg.mobile}
                onChange={handleModalInput}
                onKeyPress={filterNumberAndSpaceKeyPress}
                onPaste={filterNumberPaste}
              />
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="flex flex-col gap-2">
            <span
              className={`body-sm font-medium text-red-600
                        ${
                          !!Object.values(isEditModalErr).some((err) => !!err)
                            ? "visible"
                            : "invisible"
                        }`}
            >
              Some fields were invalid
            </span>

            <Button
              disabled={
                isEditModalLoading ||
                (org?.orgId?.split(" ")?.[1] === editModalOrg.orgId &&
                  org?.orgId?.split(" ")?.[0] === "INVALID_ORG")
              }
              leftIcon={<HiCheck className="size-4" />}
              label={"Submit"}
              variant={"blue"}
              onClick={handleModalSubmit}
            />
          </div> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
