import {
  useRef,
  useState,
} from "react";

import {
  Button,
  FlowbitePagination,
  RemoveUsersModal,
  UpdateUserModal,
} from "components";
import {
  Table,
  Tooltip,
} from "flowbite-react";
import { useTableScrollPosition } from "hooks";
import {
  HiOutlinePencil,
  HiOutlineTrash,
} from "react-icons/hi";
import Flag from "react-world-flags";
import {
  getCountryCodeFromPhoneNumber,
  openZEWidget,
} from "utils";

export function UsersTable({ data, handleSync = async () => {} }) {
  const [page, setPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isMultipleSelected, setIsMultipleSelected] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateUser, setUpdateUser] = useState({});
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [removeUsers, setRemoveUsers] = useState([]);

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(data?.length / pageOption);

  const getUserRenders = () => {
    const startIndex = (page - 1) * pageOption;
    const currentUsers = data?.slice(startIndex, startIndex + pageOption);

    return currentUsers?.map((user, index) => (
      <UserTableRow
        key={index}
        user={user}
        index={index}
        scrollPos={tableScroll.scrollLeft}
        isSelected={selectedUsers.includes(user)}
        onSelectChange={handleRowSelectChange}
        handleUpdateUser={handleUpdateUser}
        handleRemoveUsers={handleRemoveUsers}
      />
    ));
  };

  const getUserRendersMobile = () => {
    const startIndex = (page - 1) * pageOption;
    const currentUsers = data?.slice(startIndex, startIndex + pageOption);

    return currentUsers?.map((user, index) => (
      <UserTableRowMobile
        key={index}
        user={user}
        index={index}
        isSelected={selectedUsers.includes(user)}
        onSelectChange={handleRowSelectChange}
        handleUpdateUser={handleUpdateUser}
        handleRemoveUsers={handleRemoveUsers}
      />
    ));
  };

  const handleUpdateUser = (user) => {
    setIsUpdateModalOpen(true);
    setUpdateUser(user);
  };

  const handleCloseUpdateUsersModal = () => {
    setIsUpdateModalOpen(false);
    setUpdateUser({});
  };

  const handleRemoveUsers = async (users = []) => {
    setIsRemoveModalOpen(true);
    setRemoveUsers(users);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
    setRemoveUsers([]);
  };

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setPage(Math.min(page, totalPages));
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleRowSelectChange = (isChecked, user) => {
    const updatedSelectedRows = isChecked
      ? [...selectedUsers, user]
      : selectedUsers.filter((row) => row !== user);
    setSelectedUsers(updatedSelectedRows);
    setIsAllSelected(updatedSelectedRows?.length === data?.length);
    setIsMultipleSelected(updatedSelectedRows?.length > 0);
  };

  const handleSelectAllChange = (isChecked) => {
    setSelectedUsers(isChecked ? data : []);
    setIsAllSelected(isChecked);
    setIsMultipleSelected(isChecked);
  };

  return (
    <>
      <section className="cybercert-admin-setting-user-table border-primary flex flex-col self-stretch">
        {/****************************** PC TABLE ******************************/}
        <div className="hidden laptop:block">
          <Table className="min-w-[1280px]" ref={tableRef} hoverable>
            <Table.Head>
              <Table.HeadCell
                className={`w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
              >
                Name
              </Table.HeadCell>
              <Table.HeadCell className="w-64">Email</Table.HeadCell>
              <Table.HeadCell className="w-36">Mobile</Table.HeadCell>
              <Table.HeadCell className="w-36">Status</Table.HeadCell>
              <Table.HeadCell className="w-52">Last Online</Table.HeadCell>
              <Table.HeadCell className="w-38">Manage</Table.HeadCell>
            </Table.Head>

            <Table.Body>{getUserRenders()}</Table.Body>
          </Table>
        </div>

        {/****************************** MOBILE TABLE ******************************/}
        <div className="laptop:hidden">
          <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
            Users
          </div>

          <div className="flex flex-col border-x">{getUserRendersMobile()}</div>
        </div>

        <FlowbitePagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={data?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      </section>

      <UpdateUserModal
        isOpen={isUpdateModalOpen}
        user={updateUser}
        handleCloseModal={handleCloseUpdateUsersModal}
        handleSync={handleSync}
      />

      <RemoveUsersModal
        isOpen={isRemoveModalOpen}
        users={removeUsers}
        handleCloseModal={handleCloseRemoveModal}
        handleSync={handleSync}
      />
    </>
  );
}

function UserTableRow({
  user,
  index,
  scrollPos,
  handleUpdateUser = () => {},
  handleRemoveUsers = () => {},
}) {
  return (
    <Table.Row>
      <Table.Cell
        className={`sticky z-5 left-16${scrollPos > 0 ? " shadow-table" : ""}`}
      >
        {index === 0 ? (
          <div className="flex items-center gap-3">
            {user?.name}
            <div className="btn-bg btn-text items-center justify-center rounded-md px-2.5 py-0.5 text-center text-xs font-semibold">
              Initial Admin
            </div>
          </div>
        ) : (
          user?.name
        )}
      </Table.Cell>
      <Table.Cell>{user?.email}</Table.Cell>
      <Table.Cell>
        <div className="flex items-center gap-2">
          <Flag
            code={getCountryCodeFromPhoneNumber(user?.phone)}
            className="h-3.5 w-5"
            fallback={<span>🌏</span>}
          />
          +{user?.phone}
        </div>
      </Table.Cell>
      <Table.Cell>{user?.status || "-"}</Table.Cell>
      <Table.Cell>{user?.online}</Table.Cell>
      <Table.Cell>
        {index === 0 ? (
          <Button label={"Contact Us"} variant="white" onClick={openZEWidget} />
        ) : (
          <div className="flex flex-row items-center gap-2 border-0 font-medium">
            <Tooltip content="Edit" placement="bottom">
              <button
                className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:bg-gray-300"
                onClick={() => handleUpdateUser(user)}
              >
                <HiOutlinePencil className="size-4" />
              </button>
            </Tooltip>

            <Tooltip content="Remove" placement="bottom">
              <button
                className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
                onClick={() => handleRemoveUsers([user])}
              >
                <HiOutlineTrash className="size-4" />
              </button>
            </Tooltip>
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

function UserTableRowMobile({
  user,
  index,
  handleUpdateUser = () => {},
  handleRemoveUsers = () => {},
}) {
  return (
    <div className="hover:surface-db-stepper flex flex-col gap-4 border-b p-4 font-medium">
      <div className="grid grid-cols-1 gap-4 tablet:grid-cols-[auto_1fr]">
        <span className="text-gray">Name:</span>
        <span>{user?.name}</span>
        <span className="text-gray">Email:</span>
        <span>{user?.email}</span>
        <span className="text-gray">Mobile:</span>
        <div className="flex items-center gap-2">
          <Flag
            code={getCountryCodeFromPhoneNumber(user?.phone)}
            className="h-3.5 w-5"
            fallback={<span>🌏</span>}
          />
          +{user?.phone}
        </div>
        <span className="text-gray">Status:</span>
        <span>{user?.status || "-"}</span>
        <span className="text-gray">Last Online:</span>
        <span>{user?.online}</span>
      </div>

      {index === 0 ? (
        <Button
          customClasses="w-fit desktop:w-full"
          label={"Contact Us"}
          variant="white"
          onClick={openZEWidget}
        />
      ) : (
        <div className="flex flex-row items-center gap-2">
          <Tooltip content="Edit" placement="bottom">
            <button
              className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
              onClick={() => handleUpdateUser(user)}
            >
              <HiOutlinePencil className="size-4" />
            </button>
          </Tooltip>

          <Tooltip content="Remove" placement="bottom">
            <button
              className="text-gray rounded p-1 hover:bg-gray-200 focus:ring-2 focus:ring-gray-400 active:hover:bg-gray-300"
              onClick={() => handleRemoveUsers([user])}
            >
              <HiOutlineTrash className="size-4" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
