import React, { useEffect, useState } from "react";

import { EditPartnerOrgsData } from "api";
import {
  Button,
  InternationalPhoneNumberInput,
  OverlaySpinner,
  TextInput,
} from "components";
import { useDashboard } from "contexts";
import { Alert, Modal } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { HiCheck, HiExclamation } from "react-icons/hi";
import {
  getCountryCodeFromPhoneNumber,
  validateOrgId,
  validateEmail,
  validateEmpty,
  validateInternationalPhoneNumber,
} from "utils";
import { ALLOWED_COUNTRIES } from "variables";

export function ReviewOrganisationsEditModal({
  isOpen = false,
  allOrgs = [],
  editOrgs = [],
  startPolling = () => {},
  setLoading = () => {},
  handleCloseModal = () => {},
}) {
  const { dashboard } = useDashboard();

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [editedOrgs, setEditedOrgs] = useState([]);
  const [showEditWarn, setShowEditWarn] = useState(false);
  const [showEditErr, setShowEditErr] = useState(null);

  const checkduplicateOrgIds = (editedOrgs, currentIndex, newOrgId) => {
    let isduplicateOrgId = false;

    // Strip new orgid here for processing
    newOrgId = newOrgId?.trim()?.replace(/\s/g, "");

    // Other orgIds - orgIds of the orgs being reviewed but not in the editor
    const otherOrgIds = allOrgs
      .filter(
        (_, index) =>
          !editedOrgs.map((editedOrg) => editedOrg?.oldIndex).includes(index)
      )
      .map((org) => org?.orgId?.split(" ")?.[1]);

    // Now add those orgIds from the other editor orgs and the new orgId value coming in
    const allOrgIds = [
      ...otherOrgIds,
      ...editedOrgs
        .map((editedOrg, index) => {
          if (index !== currentIndex) {
            return editedOrg?.orgId?.trim()?.replace(/\s/g, "");
          } else {
            return newOrgId;
          }
        })
        .filter((orgId) => orgId !== ""),
      ,
    ];

    // If orgId appears more than twice it is a duplicate
    editedOrgs.forEach((editedOrg, index) => {
      const checkOrgId = index !== currentIndex ? editedOrg?.orgId : newOrgId;

      if (allOrgIds.indexOf(checkOrgId) !== allOrgIds.lastIndexOf(checkOrgId)) {
        editedOrgs[index].duplicateOrgId = true;
        isduplicateOrgId = true;
      } else {
        editedOrgs[index].duplicateOrgId = false;
      }
    });

    setShowEditWarn(isduplicateOrgId);
  };

  const handleInput = (event, index) => {
    const newOrgs = [...editedOrgs];

    newOrgs[index][event?.target?.name] =
      event?.target?.name === "orgId"
        ? event?.target?.value?.replace(/ /g, "")
        : event?.target?.value;
    newOrgs[index]["error"][event?.target?.name] = false;
    if (event?.target?.country)
      newOrgs[index]["country"] = event?.target?.country;

    checkduplicateOrgIds(newOrgs, index, event?.target?.value);

    setEditedOrgs(newOrgs);
  };

  const handleDismissEditWarn = () => {
    setShowEditWarn(false);
  };

  const handleDismissEditErr = () => {
    setShowEditErr(false);
  };

  const handleSubmit = async () => {
    setIsModalLoading(true);

    let submitOrgs = [
      ...editedOrgs?.map((org) => ({
        ...org,
        name: org?.name?.trim(),
        orgId: org?.orgId?.replace(/\s/g, ""),
        email: org?.email?.trim(),
        mobile: org?.mobile?.replace(/\s/g, ""),
      })),
    ];

    let isInvalidField = false;
    submitOrgs.forEach((org) => {
      if (!validateEmpty(org?.name)) {
        org.error.name = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.orgId) || !validateOrgId(org?.orgId)) {
        org.error.orgId = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.orgId) || !validateEmail(org?.email)) {
        org.error.email = true;
        isInvalidField = true;
      }

      if (
        !validateEmpty(org?.mobile) &&
        !validateInternationalPhoneNumber(org?.mobile, org?.country)
      ) {
        org.error.mobile = true;
        isInvalidField = true;
      }
    });

    if (!!isInvalidField) {
      setShowEditErr({
        type: "field",
        msg: "You have entered an invalid field. Please check again.",
      });
      setEditedOrgs(submitOrgs);
      setIsModalLoading(false);
      return;
    }

    let isAlreadyInvalidOrgId = false;
    // If the user has inputted an orgId that has already been processed as INVALID let them know
    const alreadyinvalidOrgIds = allOrgs.reduce((result, org) => {
      const status = org?.orgId?.split(" ")?.[0];
      const orgId = org?.orgId?.split(" ")?.[1];
      if (status === "INVALID_ORG") result.push(orgId);
      return result;
    }, []);
    // Now check if any of the user edited orgIds are in the already invalid set
    submitOrgs.forEach((org) => {
      if (alreadyinvalidOrgIds.includes(org?.orgId)) {
        org.error.orgId = true;
        isAlreadyInvalidOrgId = true;
      }
    });
    if (!!isAlreadyInvalidOrgId) {
      setShowEditErr({
        type: "repeat",
        msg: "You have entered an organisation ID already processed as INVALID. Please edit or remove it.",
      });
      setEditedOrgs(submitOrgs);
      setIsModalLoading(false);
      return;
    }

    // Everything valid, now get ones that have actually changed
    const changedOrgs = submitOrgs.reduce(
      (result, org) => {
        const { name, orgId, email, mobile, country, oldIndex } = org;

        if (
          name !== allOrgs[oldIndex]?.addedAs ||
          orgId !== allOrgs[oldIndex]?.orgId?.split(" ")?.[1] ||
          email !== allOrgs[oldIndex]?.email ||
          mobile !== allOrgs[oldIndex]?.phone
        ) {
          result.old.push({
            name: allOrgs[oldIndex]?.addedAs,
            orgId: allOrgs[oldIndex]?.orgId?.split(" ")?.[1],
            email: allOrgs[oldIndex]?.email,
            mobile: allOrgs[oldIndex]?.phone,
          });
          result.new.push({ name, orgId, email, mobile, country });
        }
        return result;
      },
      { old: [], new: [] }
    );

    try {
      await EditPartnerOrgsData({
        dashboard: dashboard?.route,
        data: {
          newOrg: changedOrgs.new[0],
          oldOrg: changedOrgs.old[0],
        },
      });
      startPolling();
    } catch (error) {
      console.error(error);
    }

    setIsModalLoading(false);
    handleCloseModal();
    setLoading(true);
  };

  useEffect(() => {
    setEditedOrgs(
      editOrgs?.map((org, index) => ({
        name: org?.addedAs,
        orgId: org?.orgId?.split(" ")?.[1],
        email: org?.email,
        mobile: org?.phone,
        country: org?.country,
        error: {
          name: false,
          orgId: false,
          email: false,
          mobile: false,
        },
        duplicateOrgId: false,
        oldIndex: org?.index,
      }))
    );
  }, [isOpen]);

  useEffect(() => {
    if (
      !editedOrgs.some((org) =>
        Object.values(org.error).some((error) => !!error)
      )
    ) {
      setShowEditErr(null);
    }
  }, [editedOrgs]);

  console.log("editedOrgs", editedOrgs);

  return (
    <>
      <Modal
        show={isOpen}
        size="5xl"
        position="center"
        onClose={handleCloseModal}
      >
        <Modal.Header>
          Update{" "}
          {editOrgs?.length > 1
            ? dashboard?.orgTitle
            : dashboard?.orgTitleSingle}{" "}
        </Modal.Header>
        <Modal.Body>
          {!!isModalLoading && <OverlaySpinner />}

          <div className="flex flex-col gap-5">
            {!!showEditWarn && (
              <Alert
                color="yellow"
                onDismiss={handleDismissEditWarn}
                icon={HiExclamation}
              >
                You have entered a duplicate organisation ID. Please check you
                have not already uploaded this organisation ID.
              </Alert>
            )}

            {!!showEditErr && (
              <Alert
                color="red"
                onDismiss={handleDismissEditErr}
                icon={BiSolidErrorAlt}
              >
                {showEditErr?.msg}
              </Alert>
            )}

            <div className="flex flex-col gap-4 self-stretch">
              <div className="text-primary hidden text-sm font-medium laptop:flex laptop:gap-4">
                <span className="invisible">{editOrgs?.length}.</span>
                <span className="ml-1 w-full">{dashboard?.orgTitleSingle}</span>
                <span className="ml-1 w-full">Organisation ID</span>
                <span className="ml-1 w-full">Email</span>
                <span className="ml-1 w-full">Mobile</span>
              </div>

              {editedOrgs?.map((org, index) => (
                <EditOrg
                  key={index}
                  item={org}
                  index={index}
                  handleInput={handleInput}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full laptop:w-fit"
            label={"Submit"}
            leftIcon={<HiCheck className="size-4" />}
            variant={"blue"}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

function EditOrg({ item, index, handleInput = () => {} }) {
  const { dashboard } = useDashboard();

  return (
    <div className="flex flex-col items-start gap-4 laptop:flex-row laptop:items-center">
      <div className="text-primary flex flex-row gap-1 text-sm font-medium">
        <span className="flex laptop:hidden">{dashboard?.orgTitleSingle}</span>{" "}
        {index + 1}.
      </div>

      <TextInput
        color={!!item?.error?.["name"] ? "failure" : "gray"}
        name="name"
        placeholder={`Enter ${dashboard?.orgTitleSingle} name`}
        required
        type="text"
        value={item.name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        color={
          !!item?.error?.["orgId"]
            ? "failure"
            : !!item?.duplicateOrgId
              ? "warning"
              : "gray"
        }
        name="orgId"
        placeholder={`Enter ${dashboard?.orgSingle} ${
          Object.entries(ALLOWED_COUNTRIES).find(
            ([code, country]) => country?.countryCode === item?.country
          )?.[1]?.govIdName
        }`}
        required
        type="tel"
        value={item?.orgId}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        color={!!item?.error?.["email"] ? "failure" : "gray"}
        name="email"
        placeholder={`Enter ${dashboard?.orgSingle} email`}
        required
        type="email"
        value={item?.email}
        onInput={(event) => handleInput(event, index)}
      />

      <InternationalPhoneNumberInput
        autocomplete="off"
        color={!!item?.error?.["mobile"] ? "failure" : "primary"}
        initialCountry={getCountryCodeFromPhoneNumber(item?.mobile)}
        initialValue={item?.mobile}
        id={`mobile-${index}`}
        label="Optional"
        name="mobile"
        placeholder={`Enter ${dashboard?.orgSingle} mobile`}
        required
        onInput={(event) => handleInput(event, index)}
      />
    </div>
  );
}
