import { useEffect, useState } from "react";

import { setupPartnerAccount } from "api";
import { LoadingSpinner } from "components";
import { useSyncVerifyPartnerAccount, useVerifyPartnerAccount } from "hooks";
import { Footer, Navbar } from "layouts";
import { SidebarProcess } from "layouts/sidebar/sidebar-process";
import { HiBadgeCheck } from "react-icons/hi";
import { parseDateShort, scrollToTop } from "utils";
import { ALLOWED_COUNTRIES, ROUTES } from "variables";

import { DoneOnboarding } from "./onboarding-done";
import { MFASetupOnboarding } from "./onboarding-mfa";
import { OrganizationOnboarding } from "./onboarding-organization";
import { PartnerTypeOnboarding } from "./onboarding-partner-type";
import { UserProfileOnboarding } from "./onboarding-user-profile";

const STEPS = ["TYPE", "ORGANISATION", "PROFILE", "MFA", "CONFIRM"];

export function PartnerOnboarding() {
  const [isLoading, setIsLoading] = useState(false);
  const [organisation, setOrganisation] = useState();
  const [partner, setPartner] = useState();
  const [currentStep, setCurrentStep] = useState(4);
  const [progress, setProgress] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const [stepInput, setStepInput] = useState();
  const verifyPartnerState = useVerifyPartnerAccount();
  const syncVerifyPartnerState = useSyncVerifyPartnerAccount();

  const onboardingStepTitles = [
    "Partner Type",
    "Your Organisation",
    "User Profile",
    "Setup MFA",
    "Finish",
  ];

  useEffect(() => scrollToTop(), [currentStep]);

  useEffect(() => {
    if (!!verifyPartnerState?.data) {
      setup();
    }
  }, [verifyPartnerState?.data]);

  const setup = async () => {
    const verify = verifyPartnerState?.data;
    console.log(verify);
    if (!!verify?.partner) {
      setPartner({
        ...verify.partner,
        partnerType: [
          // "isTypeReferrer",
          "isTypeSupplyChain",
          "isTypeMembership",
          "isTypeConsultant",
          "isTypeMSP",
          "isTypeFranchise",
          "isTypeVendor",
        ]
          .map((k, i) => (verify?.partner[k] === true ? i : null))
          .filter(Boolean)
          .map(
            (i) =>
              [
                // "Referrer",
                "Supply Chain",
                "Membership",
                "Consultant",
                "MSP",
                "Franchise",
                "Vendor",
              ][i]
          )
          .join(", "),
        programType: "Cyber Assurance Program (CAP)",
        isMfaDone: !!verify?.user?.mfaUpdatedAt,
      });
      const countryName = Object.entries(ALLOWED_COUNTRIES).find(
        ([key, value]) => value?.countryCode === verify?.organisation?.country
      )?.[0];
      setOrganisation({
        ...verify?.organisation,
        countryName,
        govIdName: ALLOWED_COUNTRIES[countryName]?.govIdName,
      });
      setCurrentStep(
        !!verify.partner.onboardStep
          ? STEPS.indexOf(verify.partner.onboardStep)
          : 0
      );
    }
    setIsLoading(false);
  };

  const handlePrevStep = async () => {
    setIsLoading(true);

    let result = await setupPartnerAccount({
      prev: true,
    });
    setPartner((prev) => Object.assign(prev, result?.partner));
    setCurrentStep((prev) => Math.max(0, prev - 1));

    setIsLoading(false);
  };

  // updated to mark last step as completed
  const handleNextStep = async () => {
    setIsLoading(true);

    try {
      let result;
      if (currentStep === 0) {
        result = await setupPartnerAccount({
          isTypeCAP: true,
          isTypeReferrer: partner?.isTypeReferrer,
          isTypeSupplyChain: partner?.isTypeSupplyChain,
          isTypeMembership: partner?.isTypeMembership,
          isTypeConsultant: partner?.isTypeConsultant,
          isTypeMSP: partner?.isTypeMSP,
        });
      } else if (currentStep === 1) {
        result = await setupPartnerAccount({
          name: stepInput?.name,
          website: stepInput?.website,
          logo: stepInput?.logo,
          favicon: stepInput?.favicon,
        });
      } else if (currentStep === 2) {
        result = await setupPartnerAccount({
          firstName: stepInput?.firstName,
          lastName: stepInput?.lastName,
          phone: stepInput?.phone,
          role: stepInput?.role,
          email: stepInput?.email,
        });
      } else if (currentStep === 3) {
        result = await setupPartnerAccount({});
      } else if (currentStep === 4) {
        result = await setupPartnerAccount({});
      }

      await syncVerifyPartnerState();

      if (!!result?.result) {
        if (result?.result === "DONE") {
          window.location.href = ROUTES.ROOT;
        }
        setCurrentStep((prev) => prev + 1);
        setPartner((prev) => Object.assign(prev, result?.partner));
      }
    } catch (error) {
      setCurrentStep((prev) => prev + 1);
      console.error(error.toString());
    }

    setIsLoading(false);
  };

  // Update SidebarProcess steps based on progress
  const sidebarSteps = Object.keys(progress).map((step, index) => {
    if (index < currentStep) {
      return "completed"; // Mark previous steps as completed
    } else if (index === currentStep) {
      return progress[index] ? "completed" : "inprogress"; // Mark the current step as completed if its progress is true, otherwise mark as in progress
    } else {
      return "todo"; // Mark future steps as todo
    }
  });

  return (
    <>
      <Navbar variant="primeOnboarding" />

      <div className="surface-primary flex grow flex-col self-stretch laptop:flex-row">
        <SidebarProcess
          title="Sections"
          hasHeading={true}
          steps={sidebarSteps}
          stepTitles={onboardingStepTitles}
        />

        <div className="flex w-full flex-col justify-between self-stretch">
          <div className="flex w-full flex-grow flex-col justify-between gap-[150px] self-stretch p-8 laptop:gap-8">
            <div className="flex w-full flex-grow flex-col justify-between gap-8 self-stretch">
              <div className="hidden w-fit flex-col self-stretch laptop:flex">
                <div className="text-primary text-2xl font-bold">
                  Welcome to CyberCert's Partner Program
                </div>
                <div className="text-gray flex items-center gap-2 text-base font-medium">
                  <HiBadgeCheck className="size-5" />
                  Partner Onboarding
                </div>
              </div>

              <div className="flex flex-grow flex-col gap-8 self-stretch">
                {!!isLoading ? (
                  <LoadingSpinner />
                ) : (
                  [
                    <PartnerTypeOnboarding
                      handleNextStep={handleNextStep}
                      partner={partner}
                    />,
                    <OrganizationOnboarding
                      handleNextStep={handleNextStep}
                      handlePrevStep={handlePrevStep}
                      partner={partner}
                      organisation={organisation}
                      input={stepInput}
                      setInput={setStepInput}
                    />,
                    <UserProfileOnboarding
                      partner={partner}
                      input={stepInput}
                      setInput={setStepInput}
                      handleNextStep={handleNextStep}
                      handlePrevStep={handlePrevStep}
                    />,
                    <MFASetupOnboarding
                      partner={partner}
                      handleNextStep={handleNextStep}
                      handlePrevStep={handlePrevStep}
                    />,
                    <DoneOnboarding
                      organisation={organisation}
                      partner={partner}
                      mfaDate={parseDateShort(
                        verifyPartnerState?.data?.user?.mfaUpdatedAt
                      )}
                      handleNextStep={handleNextStep}
                      handlePrevStep={handlePrevStep}
                    />,
                  ][currentStep]
                )}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}
