import { API } from "aws-amplify";

export const fetchSuppliersWidgetData = async ({ dashboard }) => {
  let result;

  try {
    const myInit = {
      queryStringParameters: {
        dashboard,
      },
    };
    result = await API.get("partner", "/suppliers/fetch", myInit);
    // console.log(result);
  } catch (e) {
    console.error(e);
  }
  return result;
};

export const fetchSupplierDetails = async ({ supplierCCID }) => {
  const myInit = {
    queryStringParameters: {
      supplierCCID,
    },
  };
  const result = await API.get("partner", "/suppliers/fetch", myInit);
  // console.log(result);
  return result;
};
