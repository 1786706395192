import { useEffect, useRef, useState } from "react";

import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { Button, FilterButton, FlowbitePagination } from "components";
import { useDashboard } from "contexts";
import { Table } from "flowbite-react";
import { useTableScrollPosition } from "hooks/table-scroll";
import { BsShieldFillExclamation } from "react-icons/bs";
import { HiEye, HiFilter, HiX } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { getAppPage, getClientListItemStatus } from "services";
import { ROUTES } from "variables";

const certIcons = {
  Bronze: IconBronze,
  Silver: IconSilver,
  Gold: IconGold,
  Platinum: IconPlatinum,
  Diamond: IconDiamond,
};

const FILTERS = [
  "Assessed",
  "Certified",
  "In Progress",
  "Non-Certified",
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
  "Diamond",
];

export function OrgTable({ data }) {
  const { dashboard } = useDashboard();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filteredData, setFilteredData] = useState();

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(filteredData?.length / pageOption);

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setCurrentPage(
      Math.min(currentPage, Math.ceil(filteredData?.length / value))
    );
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const getOrgRenders = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentOrgs = filteredData?.slice(
      startIndex,
      startIndex + pageOption
    );

    return currentOrgs?.map((org, index) =>
      !!org ? (
        <OrgTableRow
          key={index}
          org={org}
          isScrolling={tableScroll.scrollLeft > 0}
        />
      ) : (
        <></>
      )
    );
  };

  const getOrgRendersMobile = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentOrgs = filteredData?.slice(
      startIndex,
      startIndex + pageOption
    );

    return currentOrgs?.map((org, index) =>
      !!org ? <OrgTableMobileRow key={index} org={org} /> : <></>
    );
  };

  const toggleFilter = (filter) => {
    if (selectedFilter.includes(filter)) {
      setSelectedFilter(selectedFilter.filter((item) => item !== filter));
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const clearFilters = () => {
    setSelectedFilter([]);
  };

  function getFilteredOrgData(data, filters) {
    if (filters?.length === 0) return data;

    let result = [];
    if (filters.includes("Assessed")) {
      result = [
        ...result,
        ...data.filter((item) => item?.statusTxt === "Assessed"),
      ];
    }

    if (filters.includes("Certified")) {
      result = [
        ...result,
        ...data.filter((item) => item?.statusTxt === "Completed"),
      ];
    }
    if (filters.includes("In Progress")) {
      result = [
        ...result,
        ...data.filter((item) => item?.statusTxt === "In progress"),
      ];
    }
    if (filters.includes("Non-Certified")) {
      result = [
        ...result,
        ...data.filter((item) => item?.certification === "non-certified"),
      ];
    }
    if (filters.includes("Bronze")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Bronze")
          // || item?.inProgressCertification?.includes("Bronze"),
        ),
      ];
    }
    if (filters.includes("Silver")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Silver")
          // || item?.inProgressCertification?.includes("Silver"),
        ),
      ];
    }
    if (filters.includes("Gold")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Gold")
          // || item?.inProgressCertification?.includes("Gold"),
        ),
      ];
    }
    if (filters.includes("Platinum")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Platinum")
          // || item?.inProgressCertification?.includes("Platinum"),
        ),
      ];
    }
    if (filters.includes("Diamond")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Diamond")
          // || item?.inProgressCertification?.includes("Diamond"),
        ),
      ];
    }

    const filteredResult = data
      .map((item) => result.filter((r) => r?.orgId === item?.orgId)[0])
      .filter(Boolean);
    return filteredResult;
  }

  useEffect(() => {
    let updatedOrgData = data?.suppliers?.map((supplier) =>
      getClientListItemStatus(supplier)
    );

    const filteredData = getFilteredOrgData(updatedOrgData, selectedFilter);

    setFilteredData(filteredData);
  }, [data, selectedFilter]);

  return (
    <section className="cybercert-organisations-table border-primary flex flex-col self-stretch">
      <div className="flex flex-col tablet:flex-row items-center justify-center gap-3 self-stretch border border-b-0 p-4">
        <div className="flex items-center gap-2 self-stretch py-2">
          <HiFilter className="text-icons size-4" />
          <span className="text-secondary text-xs font-medium">
            {dashboard.orgTitleSingle}
          </span>
        </div>

        <div className="flex flex-grow flex-wrap items-center gap-1 self-stretch">
          {FILTERS?.map((filter, index) => (
            <div
              key={index}
              onClick={() => {
                toggleFilter(filter);
              }}
            >
              <FilterButton
                impIsSelected={selectedFilter.includes(filter)}
                key={index}
              >
                {filter}
              </FilterButton>
            </div>
          ))}
        </div>

        <Button
          onClick={clearFilters}
          label="Clear Filters"
          leftIcon={<HiX className="text-icons size-4" />}
          variant="white"
        />
      </div>

      {/******************************** PC TABLE *********************************/}
      <div className="hidden laptop:block">
        <Table ref={tableRef} hoverable>
          <Table.Head>
            <Table.HeadCell
              className={`w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
            >
              Organisation
            </Table.HeadCell>
            <Table.HeadCell className="w-64 laptop:w-96">
              Certification
            </Table.HeadCell>
            <Table.HeadCell className="w-36">Status</Table.HeadCell>
            <Table.HeadCell className="w-36">Expiry Date</Table.HeadCell>
            <Table.HeadCell className="w-36">Assessment</Table.HeadCell>
          </Table.Head>

          <Table.Body>
            {!!filteredData?.length ? (
              getOrgRenders()
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5} className="text-center">
                  No {dashboard?.orgTitle?.toLowerCase()} currently listed
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      {/****************************** MOBILE TABLE *******************************/}
      <div className="block laptop:hidden">
        <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
          Organisations
        </div>

        <div className="flex flex-col border-x">
          {!!filteredData?.length ? (
            getOrgRendersMobile()
          ) : (
            <div className="border-b p-5 text-center">
              No {dashboard?.orgTitle?.toLowerCase()} currently listed
            </div>
          )}
        </div>
      </div>

      {filteredData?.length > 0 && (
        <FlowbitePagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={filteredData?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      )}
    </section>
  );
}

function OrgTableRow({ org, isScrolling }) {
  const navigate = useNavigate();

  const getCertificationIndex = (certifications, certificationLevel) => {
    return certifications.findIndex(
      (cert) =>
        cert?.progress?.level === certificationLevel &&
        cert?.progress?.status === "COMPLETED"
    );
  };

  return (
    <Table.Row>
      <Table.Cell
        className={`hover:text-link cursor-pointer capitalize font-medium${isScrolling ? " shadow-table" : ""}`}
        onClick={() => {
          navigate(ROUTES.ORGANISATION_DETAILS.replace(":ccid", org?.ccid));
        }}
      >
        {org?.name}
      </Table.Cell>
      <Table.Cell>
        {!!org?.certification ? (
          !!certIcons[org?.certification] ? (
            <Link
              className="hover:text-link flex w-fit items-center gap-2.5 rounded-full outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-8"
              to={`${getAppPage()}/issued/${org?.certificationId}`}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <img
                src={certIcons[org?.certification]}
                alt={`Icon${org?.certification}`}
                className="size-6.5 flex-shrink-0"
              />
              <span>
                <span className="uppercase">{org?.certification}</span> SMB1001:
                {
                  org?.certifications[
                    getCertificationIndex(
                      org?.certifications,
                      [
                        null,
                        "Bronze",
                        "Silver",
                        "Gold",
                        "Platinum",
                        "Diamond",
                      ].indexOf(org?.certification)
                    )
                  ]?.standard
                }
              </span>
            </Link>
          ) : org?.recommended ? (
            <div className="flex flex-row items-center gap-3">
              <BsShieldFillExclamation className="flex-shrink-0 text-icons h-6 w-6" />
              <span>{org?.certification}</span>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-3">
              <BsShieldFillExclamation className="flex-shrink-0 text-icons h-6 w-6" />
              <span>Non-Certified</span>
            </div>
          )
        ) : org?.recommended ? (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="flex-shrink-0 text-icons h-6 w-6" />
            {org?.certification}
          </div>
        ) : (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="flex-shrink-0 text-icons h-6 w-6" />
            Non-Certified
          </div>
        )}
      </Table.Cell>
      <Table.Cell>{org?.status}</Table.Cell>
      <Table.Cell>{org?.expiry}</Table.Cell>
      <Table.Cell>
        {!!org?.assessmentResults?.[0] ? (
          <button
            className="text-primary hover:text-link flex w-fit items-center justify-center gap-2 rounded-lg"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              navigate(
                ROUTES.RESULT_GAP.replace(
                  ":id",
                  org?.assessmentResults?.filter((r) => !!r?.completedAt)[0]?.id
                )
              );
            }}
          >
            <HiEye className="flex-shrink-0 size-4" />
            <span className="font-semibold">View</span>
          </button>
        ) : (
          "None"
        )}
      </Table.Cell>
    </Table.Row>
  );
}

function OrgTableMobileRow({ org }) {
  const navigate = useNavigate();

  return (
    <div className="hover:surface-db-stepper flex flex-col gap-4 border-b p-5">
      <div
        className="text-primary hover:text-link flex cursor-pointer items-center gap-3 text-base font-medium capitalize"
        onClick={() => {
          navigate(ROUTES.ORGANISATION_DETAILS.replace(":ccid", org?.orgId));
        }}
      >
        {org?.name}
      </div>
      <div className="flex flex-col gap-4">
        <div className="ml-1 flex items-center gap-3">
          {!!org?.certification ? (
            <>
              {!!certIcons[org?.certification] ? (
                <Link
                  className="hover:text-link flex w-fit items-center gap-3 rounded-full outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-8"
                  to={`${getAppPage()}/issued/${org?.certificationId}`}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  <img
                    src={certIcons[org?.certification]}
                    alt={`Icon${org?.certification}`}
                    className="size-6.5"
                  />
                  <span className="uppercase">{org?.certification}</span>
                </Link>
              ) : (
                <>
                  {org?.recommended ? (
                    <>
                      <BsShieldFillExclamation className="text-icons h-6 w-6" />
                      {org?.certification}
                    </>
                  ) : (
                    <>
                      <BsShieldFillExclamation className="h-6 w-6 text-gray-300" />
                      Non-Certified
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {org?.recommended ? (
                <>
                  <BsShieldFillExclamation className="text-icons h-6 w-6" />
                  {org?.certification}
                </>
              ) : (
                <>
                  <BsShieldFillExclamation className="h-6 w-6 text-gray-300" />
                  Non-Certified
                </>
              )}
            </>
          )}
        </div>
        <div className="cursor-pointer">{org?.status}</div>
        {!!org?.expiry && (
          <div className="font-medium">
            <span className="text-gray pr-1 font-normal">Expiry date:</span>
            {org?.expiry}
          </div>
        )}
        {!!org?.assessmentResults[0] ? (
          <button
            className="text-primary hover:text-link flex w-fit items-center justify-start gap-2 rounded-lg"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              navigate(
                ROUTES.RESULT_GAP.replace(
                  ":id",
                  org?.assessmentResults?.filter((r) => !!r?.completedAt)[0]?.id
                )
              );
            }}
          >
            <HiEye className="size-4" />
            <span className="font-semibold">View Assessments</span>
          </button>
        ) : (
          "No Assessment Completed"
        )}
      </div>
    </div>
  );
}
