import { useEffect, useState } from "react";

import { fetchGAPAssessmentResult } from "api";
import { AlternateTableTheme } from "assets";
import {
  AssessmentsResultsPanel,
  Breadcrumbs,
  Button,
  DetailRow,
  DisplayLevelIcon,
  LoadingSpinner,
} from "components";
import { DashboardOptions } from "contexts";
import { Table } from "flowbite-react";
import { PageHeader } from "layouts";
import { BsShieldFillExclamation } from "react-icons/bs";
import {
  HiArrowLeft,
  HiChartPie,
  HiCheck,
  HiOutlineDeviceMobile,
  HiOutlineMail,
} from "react-icons/hi";
import { RiInformationLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { getBaseIdRequirements, getFlatGAPAssessmentQuestions } from "services";
import { parseDateTimeShort } from "utils";
import { INITIAL_GAP_ASSESSMENTS, ROUTES } from "variables";

const RESULTS = [null, "BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function ResultGapAssessment() {
  const [loading, setLoading] = useState(true);
  const [dashboardContext, setDashboardContext] = useState();
  const [assessmentResult, setAssessmentResult] = useState();
  const [sortedResults, setSortedResults] = useState();
  const navigate = useNavigate();
  const params = useParams();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history
  };

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    // display no first in descending order
    let flatQuestions = getFlatGAPAssessmentQuestions(
      assessmentResult?.result?.gapResults?.slice()
    );
    setSortedResults(
      flatQuestions.sort((a, b) => {
        if (a.value === "no" && b.value === "no") {
          return a.requirement.localeCompare(b.requirement);
        } else if (a.value === "no") {
          return -1;
        } else if (b.value === "no") {
          return 1;
        } else {
          return a.requirement.localeCompare(b.requirement);
        }
      })
    );
  }, [assessmentResult]);

  const setup = async () => {
    setLoading(true);
    const result = await fetchGAPAssessmentResult({
      id: params?.id,
    });

    let dashboard = result?.assessment?.dashboard;
    let ctx = DashboardOptions?.filter((c) => c?.route === dashboard)[0];
    setDashboardContext(ctx);

    setAssessmentResult({
      ...result,
      assessment: {
        ...result?.assessment,
        assessmentName:
          INITIAL_GAP_ASSESSMENTS[
            INITIAL_GAP_ASSESSMENTS.length - result?.assessment?.maximumLevel
          ]?.level,
      },
      result: {
        ...result?.result,
        govIdName: JSON.parse(result?.organisation?.govId || "{}")
          ?.identifiers?.[0]?.type,
        qualifiedLevel: RESULTS[result?.result?.result],
        completedOn: parseDateTimeShort(result?.result?.completedAt),
        gapResults: result?.result?.answers?.map((answer) => ({
          ...answer,
          id: `${answer.id}.0`,
          status: answer.value === "yes" ? "implemented" : "missing",
          question: result?.assessment?.questionnaire
            .flatMap((a) => a)
            .filter((a) => a.requirement === answer.requirement)[0]?.question,
        })),
      },
    });
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="hidden laptop:block">
            <Breadcrumbs
              icon={HiChartPie}
              items={{
                "": ROUTES.ROOT,
                Assessments: ROUTES.ASSESSMENTS,
                "SMB1001 GAP Assessments": ROUTES.GAP,
                [assessmentResult?.assessment?.assessmentName]:
                  ROUTES.VIEW_GAP.replace(
                    ":id",
                    assessmentResult?.assessment?.id
                  ),
                [assessmentResult?.result?.name]: "",
              }}
            />
          </div>

          {/* <button
            onClick={handleGoBack}
            className="flex flex-row gap-2 items-center text-primary font-semibold"
          >
            <HiArrowLeft className="w-4 h-4" /> Back to All results
          </button> */}

          <div className="flex justify-start">
            <Button
              customClasses={`focus:ring-4 focus:ring-blue-300`}
              onClick={handleGoBack}
              leftIcon={<HiArrowLeft className="size-4" />}
              label={`Back to All results`}
              labelStyles={`font-semibold`}
            />
          </div>

          <div className="text-primary text-xl font-bold">
            <h2>{assessmentResult?.result?.name}</h2>
            <p className="text-gray text-sm font-medium">
              {assessmentResult?.result?.govIdName}{" "}
              <span className="text-normal text-primary">
                {assessmentResult?.result?.orgId}
              </span>
            </p>
          </div>

          <div className="flex flex-col gap-5 self-stretch">
            <span className="text-primary text-base font-bold">Summary</span>

            <div className="flex flex-col gap-8 laptop:flex-row">
              <div className="flex w-full flex-col">
                <div>
                  <DetailRow label={"Implemented requirements"}>
                    <span className="label-completed-text">
                      {Math.min(
                        getBaseIdRequirements(
                          assessmentResult?.result?.implementedRequirements
                        )?.length,
                        assessmentResult?.assessment?.GAP[
                          assessmentResult?.assessment?.GAP?.length - 1
                        ]?.length
                      )}
                    </span>{" "}
                    /{" "}
                    {
                      assessmentResult?.assessment?.GAP[
                        assessmentResult?.assessment?.GAP?.length - 1
                      ]?.length
                    }
                  </DetailRow>
                  <DetailRow label={"Qualified level"}>
                    <div className="flex flex-row items-center gap-0">
                      {!!assessmentResult?.result?.qualifiedLevel ? (
                        <>
                          <DisplayLevelIcon
                            level={assessmentResult?.result?.qualifiedLevel}
                          />
                          {assessmentResult?.result?.qualifiedLevel}
                        </>
                      ) : !!assessmentResult?.result?.completedAt ? (
                        <>
                          <BsShieldFillExclamation className="text-icons mr-2 size-4" />
                          Ineligible for minimum certification
                        </>
                      ) : (
                        <>Assessment not completed</>
                      )}
                    </div>
                  </DetailRow>
                  <DetailRow label={"Completed by"}>
                    {assessmentResult?.result?.completedBy}
                  </DetailRow>
                  <DetailRow label={"Verified via"}>
                    <div className="flex flex-row items-center gap-2">
                      {assessmentResult?.result?.verifiedVia &&
                        (() => {
                          switch (assessmentResult?.result?.verifiedVia) {
                            case "email":
                              return (
                                <HiOutlineMail className="text-icons size-5" />
                              );
                            case "mobile":
                              return (
                                <HiOutlineDeviceMobile className="text-icons size-5" />
                              );
                            default:
                              return null;
                          }
                        })()}
                      {assessmentResult?.result?.verifiedData}
                    </div>
                  </DetailRow>
                  <DetailRow label={"Completed on"}>
                    {assessmentResult?.result?.completedOn}
                  </DetailRow>
                </div>
              </div>

              <AssessmentsResultsPanel
                certificationStatus={
                  RESULTS[assessmentResult?.organisation?.certificationLevel]
                }
                assessmentResult={assessmentResult}
              />
            </div>
          </div>

          <PageHeader variant="titleOnly" pageTitle={"Assessment Responses"} />

          <div className="hidden laptop:block">
            <Table theme={AlternateTableTheme} hoverable>
              <Table.Head>
                <Table.HeadCell>Required For</Table.HeadCell>
                <Table.HeadCell>Question</Table.HeadCell>
                <Table.HeadCell>Answer</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {sortedResults?.map((item, index) => (
                  <Table.Row key={index} className="font-medium">
                    <Table.Cell>{item.requirement}</Table.Cell>
                    <Table.Cell>{item.question}</Table.Cell>
                    <Table.Cell>
                      <span className="capitalize">{item.value}</span>
                    </Table.Cell>
                    <Table.Cell>
                      {item.status &&
                        (() => {
                          switch (item.status) {
                            case "missing":
                              return (
                                <div className="text-gray flex w-fit flex-row items-center gap-1 whitespace-nowrap rounded-lg bg-gray-100 p-1 text-xs font-medium">
                                  <RiInformationLine />
                                  Control missing
                                </div>
                              );
                            case "implemented":
                              return (
                                <div className="flex w-fit flex-row items-center gap-1 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                  <HiCheck />
                                  Implemented
                                </div>
                              );
                            default:
                              return null;
                          }
                        })()}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          {/******************* Mobile *********************/}

          <div className="flex flex-col laptop:hidden">
            <div className="surface-secondary text-primary border border-b-0 p-4 text-xs font-semibold uppercase">
              RESPONSES
            </div>

            <div className="flex flex-col gap-0 border border-b-0 border-t-0">
              {sortedResults?.map((item, index) => (
                <div
                  key={index}
                  className="hover:surface-db-stepper flex flex-col gap-2 border-b p-5"
                >
                  <div className="font-medium">{item.question}</div>
                  <div className="font-medium">
                    <span className="text-gray pr-1 font-normal">
                      Required for:
                    </span>
                    {item.requirement}
                  </div>
                  <div className="font-medium capitalize">
                    <span className="text-gray pr-1 font-normal">Answer:</span>
                    {item.value}
                  </div>
                  <div className="font-medium">
                    {item.status &&
                      (() => {
                        switch (item.status) {
                          case "missing":
                            return (
                              <div className="text-gray flex w-fit flex-row items-center gap-1 whitespace-nowrap rounded-lg bg-gray-100 p-1 text-xs font-medium">
                                <RiInformationLine />
                                Control missing
                              </div>
                            );
                          case "implemented":
                            return (
                              <div className="flex w-fit flex-row items-center gap-1 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                <HiCheck />
                                Implemented
                              </div>
                            );
                          default:
                            return null;
                        }
                      })()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
